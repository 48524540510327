import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Logo from "./img/ErbsenzaehlereiLogoDarkCircleOutlines.svg";


const Impressum = ({ setAppLocation, footerHomeLink, appLocation }) => {
    const { t } = useTranslation();

    return (
        <div className="w-full flex flex-col  items-center">
            <div className="bg-white flex flex-col max-w-md  mx-auto flex-1 w-full items-center">
                <button className="flex w-4/5 items-center justify-center h-full" onClick={() => footerHomeLink()}>
                    <img className="h-full" alt="text" src={Logo} />
                </button>
                <div className="my-5 text-center w-10/12 font-londrina text-giga">
                    Impressum
                </div>

                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    Angaben gemäß § 5 TMG
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div>
                        Klarastraße 2
                        79106 Freiburg
                    </div>
                    <div className="my-3 flex flex-wrap">
                        <div>
                            Handelsregister:
                        </div>
                        <div className="ml-3">
                            HRB 725028
                        </div>
                    </div>
                    <div className="my-3 flex">
                        <div>
                            Registergericht:
                        </div>
                        <div className="ml-3">
                            Amtsgericht Freiburg im Breisgau
                        </div>
                    </div>
                    <div className="my-3 flex flex-wrap">
                        <div>
                            Vertreten durch:
                        </div>
                        <div className="ml-3 flex flex-col">
                            <div>John Lamothe </div>
                            <div>Kirill Romanenko</div>
                        </div>
                    </div>
                </div>

                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    Kontakt
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div className="my-3 flex flex-wrap">
                        <div>
                            Telefon:
                        </div>
                        <div className="ml-3">
                            +49 1609 3459 447
                        </div>
                    </div>
                    <div className="my-3 flex flex-wrap">
                        <div>
                            E-Mail:
                        </div>
                        <div className="ml-3">
                            info@erbsenzaehlerei.com
                        </div>
                    </div>
                </div>

                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    Umsatzsteuer-ID
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div className="my-3 flex flex-wrap">
                        <div>
                            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                        </div>
                        <div className="">
                            DE347266944
                        </div>
                    </div>
                </div>

                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    EU-Streitschlichtung
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div className="my-3">
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/
                        Unsere E-Mail-Adresse finden Sie oben im Impressum.
                    </div>
                </div>


                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div className="my-3">
                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                    </div>
                    <div className="my-3">
                        Quelle: <br/> https://www.e-recht24.de/impressum-generator.html
                    </div>
                </div>


                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    Partner
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div className="my-3">
                        Mit freundlicher Unterstützung von <br />
                        <a href="http://mind-hill.com" target="_blank">www.mind-hill.com</a>.
                    </div>
                </div>

                <div className="w-full flex justify-center">
                    <Footer footerHomeLink={footerHomeLink} setAppLocation={setAppLocation} appLocation={appLocation} />
                </div>
            </div>
        </div>

    );
};

export default Impressum;
