import React, { useEffect, useState } from "react";
import Logo from "./img/ErbsenzaehlereiLogoDarkCircleOutlines.svg";
//import Curve from "./img/Curve.svg";
import DownArrow from "./images/downarrow.png";
import LeftArrow from "./images/leftArrow.png"
import RightArrow from "./images/rightArrow.png"
import TitlelSalat from "./img/TitlelSalat.jpg";
//import Bildreihe1 from "./images/Bildreihe-1.jpg";
//import Bildreihe2 from "./images/Bildreihe-2.jpg";
//import Bildreihe3 from "./images/Bildreihe-3.jpg";
import Bildreihe4 from "./images/Bildreihe-4.jpg";
import Bildreihe5 from "./images/Bildreihe-5.jpg";
import Bildreihe6 from "./images/Bildreihe-6.jpg";
import { useSwipeable } from "react-swipeable";
import Footer from "./Footer";
import Kirill from "./images/Kirill.jpg";
import John from "./images/John.jpg";
import { Disclosure, Transition } from "@headlessui/react";
import moment from 'moment';
import 'tw-elements';
import axios from "axios";

// TODO changed by HGF
console.log("1/5: Intro.js");

/*
const week = [
  {
    id: 1,
    dayname: "MONTAG",
    bodytitle1: "Chicken Stroganof",
    body1: "Cremiger Klassiker mit Hähnchenbrust und Champignons",
    bodytitle2: "Bohnen-Borschtsch",
    body2: "Rote-Beete-Suppe mit weißen Bohnen und Weißkohl",
    bodytitle3: "Russian Bow",
    body3: "Stelle nach deinem eigenen Geschmack eine Bowl aus russischen Zutaten zusammen, wahlweise auf knackigem Salat",
  },
  {
    id: 2,
    dayname: "DIENSTAG",
    bodytitle1: "Bohnen-Borschtsch",
    body1: "Rote-Beete-Suppe mit weißen Bohnen und Weißkohl",
    bodytitle2: "Chicken Stroganof",
    body2: "Cremiger Klassiker mit Hähnchenbrust und Champignons",
    bodytitle3: "Russian Bow",
    body3: "Stelle nach deinem eigenen Geschmack eine Bowl aus russischen Zutaten zusammen, wahlweise auf knackigem Salat",
  },
  {
    id: 3,
    dayname: "MITTWOCH",
    bodytitle1: "Russian Bow",
    body1: "Stelle nach deinem eigenen Geschmack eine Bowl aus russischen Zutaten zusammen, wahlweise auf knackigem Salat",
    bodytitle2: "Bohnen-Borschtsch",
    body2: "Rote-Beete-Suppe mit weißen Bohnen und Weißkohl",
    bodytitle3: "Chicken Stroganof",
    body3: "Cremiger Klassiker mit Hähnchenbrust und Champignons",
  },
  {
    id: 4,
    dayname: "DONNERSTAG",
    bodytitle1: "Chicken Stroganof",
    body1: "Cremiger Klassiker mit Hähnchenbrust und Champignons",
    bodytitle2: "Bohnen-Borschtsch",
    body2: "Rote-Beete-Suppe mit weißen Bohnen und Weißkohl",
    bodytitle3: "Russian Bow",
    body3: "Stelle nach deinem eigenen Geschmack eine Bowl aus russischen Zutaten zusammen, wahlweise auf knackigem Salat",
  },
  {
    id: 5,
    dayname: "FREITAG",
    bodytitle1: "Bohnen-Borschtsch",
    body1: "Rote-Beete-Suppe mit weißen Bohnen und Weißkohl",
    bodytitle2: "Chicken Stroganof",
    body2: "Cremiger Klassiker mit Hähnchenbrust und Champignons",
    bodytitle3: "Russian Bow",
    body3: "Stelle nach deinem eigenen Geschmack eine Bowl aus russischen Zutaten zusammen, wahlweise auf knackigem Salat",
  },
  {
    id: 6,
    dayname: "SAMSTAG",
    bodytitle1: "Chicken Stroganof",
    body1: "Stelle nach deinem eigenen Geschmack eine Bowl aus russischen Zutaten zusammen, wahlweise auf knackigem Salat",
    bodytitle2: "Bohnen-Borschtsch",
    body2: "Rote-Beete-Suppe mit weißen Bohnen und Weißkohl",
    bodytitle3: "Chicken Stroganof",
    body3: "Cremiger Klassiker mit Hähnchenbrust und Champignons",
  },
  {
    id: 0,
    dayname: "SONNTAG",
    bodytitle1: "Bohnen-Borschtsch",
    body1: "Rote-Beete-Suppe mit weißen Bohnen und Weißkohl",
    bodytitle2: "Chicken Stroganof",
    body2: "Cremiger Klassiker mit Hähnchenbrust und Champignons",
    bodytitle3: "Russian Bow",
    body3: "Stelle nach deinem eigenen Geschmack eine Bowl aus russischen Zutaten zusammen, wahlweise auf knackigem Salat",
  },
];
*/

const slider = [
  {
    id: 1,
    header: "Personalicious =\nPrecision Nutrition",
    body: "zusammengestellt nach deinen Vorgaben, kalorien- und nährstoffgenau.",
  },
  {
    id: 2,
    header: "Nutritious =\nClean Eating",
    body: "gekocht mit frischen, möglichst naturbelassenen, echten Zutaten.",
  },
  {
    id: 3,
    header: "Delicious =\nSlow Cooking",
    body: "zubereitet mit Zeit und Ruhe - deshalb schmeckt es wie zu Hause.",
  },
  {
    id: 4,
    header: "Healthy =\nGood for You",
    body: "damit du auch unterwegs gesund bleibst.",
  },
];

const Intro = ({
  setAppLocation,
  footerHomeLink
}) => {
  const [slideNumber, setSlideNumber] = useState(1);
  const [slideWeekNumber, setSlideWeekNumber] = useState(moment().day());
  const [weeklyMenu, setWeeklyMenu] = useState([]);

  //console.log("slideWeekNumber=",slideWeekNumber);
  //console.log("weeklyMenu=",weeklyMenu);

  useEffect(() => {
    const getWeeklyMenu = async () => {
      try {
        // weekday := 0 = sunday, 1 = monday, .... 6 = saturday
        const weeklyMenuResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v0/weekly_menu.php`
        );
        setWeeklyMenu(weeklyMenuResponse.data);
      } catch (err) {
        console.error("weekly_menu error = "+err);
      }
    };
    getWeeklyMenu();
  }, []);

  function getDayOfWeek(day) {
    switch (day) {
      case 0: return "SONNTAG";
      case 1: return "MONTAG";
      case 2: return "DIENSTAG";
      case 3: return "MITTWOCH";
      case 4: return "DONNERSTAG";
      case 5: return "FREITAG";
      default: return "SAMSTAG";
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setSlideNumber((prior) => (prior === 4 ? 1 : prior + 1)),
    onSwipedRight: () =>
      setSlideNumber((prior) => (prior === 1 ? 4 : prior - 1)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  /**
  * slideWeekNumber week of the day
  * 0 = sunday, 1 = monday, .... 6 = saturday
  **/
  const handlers2 = useSwipeable({
    onSwipedLeft: () =>
      setSlideWeekNumber((prior) => (prior === 6 ? 0 : prior + 1)),
    onSwipedRight: () =>
      setSlideWeekNumber((prior) => (prior === 0 ? 6 : prior - 1)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="flex flex-col max-w-md mx-auto bg-appback overflow-hidden items-center">
      <div
        className="bg-cover flex flex-col h-full verysmart:h-screen w-full intro items-center"
        style={{ backgroundImage: `url(${TitlelSalat})` }}
      >
        <div className="flex w-4/5 items-center justify-center h-full">
          <img className="h-full" alt="text" src={Logo} />
        </div>
        <div className="flex flex-col px-11 flex-1 justify-end w-full">
          <div style={{ height: "30px", overflow: "hidden" }}>
            <svg
              viewBox="0 0 500 150"
              preserveAspectRatio="none"
              style={{ height: "100%", width: "100%" }}
            >
              <path
                d="M0.00,32.14 C230.13,259.15 161.09,-95.76 507.66,67.99 L499.55,150.00 L0.00,150.00 Z"
                style={{ stroke: "none", fill: "#ffffff" }}
              />
            </svg>
          </div>
          <div className="flex pr-5 py-1 justify-end bg-white -my-0.5 ">
            <span
              onClick={() => setSlideNumber(1)}
              className={`${slideNumber === 1 ? "bg-black" : "bg-gray-300"
                } h-2.5 w-2.5 ml-1 rounded-2xl cursor-pointer`}
            ></span>
            <span
              onClick={() => setSlideNumber(2)}
              className={`${slideNumber === 2 ? "bg-black" : "bg-gray-300"
                } h-2.5 w-2.5 ml-1 rounded-2xl cursor-pointer`}
            ></span>
            <span
              onClick={() => setSlideNumber(3)}
              className={`${slideNumber === 3 ? "bg-black" : "bg-gray-300"
                } h-2.5 w-2.5 ml-1 rounded-2xl cursor-pointer`}
            ></span>
            <span
              onClick={() => setSlideNumber(4)}
              className={`${slideNumber === 4 ? "bg-black" : "bg-gray-300"
                } h-2.5 w-2.5 ml-1 rounded-2xl cursor-pointer`}
            ></span>
          </div>
          <div {...handlers} className="relative pt-0 px-8 pb-8 bg-white min-w-full">
            <div className="text-whiteback my-5">
              <h2 className="text-base font-sans font-bold font-width-90 whitespace-pre">
                {slider.find((slide) => slide.id === slideNumber).header}
              </h2>
              <p className="my-2 text-sm text-appback font-sans font-medium font-width-85">
                {slider.find((slide) => slide.id === slideNumber).body}
              </p>
            </div>
            <div className="absolute -bottom-3 h-10 w-full left-0 flex justify-center">
              <div className=" ">
                <button
                  onClick={() => setAppLocation("gerichte")}
                  className="min-w-9 py-3 px-11 bg-buttonback text-gray-800 font-bold rounded-full items-center"
                >
                  <span className="text-white text-xs font-sans font-medium font-width-85">
                    Jetzt bestellen
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col items-center mt-8"
          >
            <div className="my-2 text-white font-sans font-normal text-tiny font-width-85 w-9/12 text-center">
              * Bestellung zur Abholung in
              unserem Laden in Freiburg
            </div>
            <div className="my-2 text-white font-sans font-medium text-tiny font-width-85">
              mehr erfahren
            </div>
            <img className="h-14" alt="text" src={DownArrow} />
          </div>
        </div>
      </div>
      <div className="py-5 bg-white w-full flex flex-col items-center">
        <div className="py-5 font-londrina text-giga text-center">WOCHENKARTE</div>
        <div className="text-center text-sm w-10/12 font-sans font-width-90 font-medium">Jeden Tag gibt es bei uns mindestens
          2 Gerichte, aus denen wir dein ganz
          persönliches Essen machen.
          Wir sind in Freiburg in der Fischerau 30.
          Komm vorbei! 11:30 bis 15:00.
        </div>
      </div>
      <div {...handlers2}>
        <div className="py-5 bg-white w-full flex flex-col items-center">
          <div className="w-10/12 border-b-2 border-t-2 border-black">
            <div className="flex justify-between items-center my-3">
              <img className="h-8" alt="text" src={LeftArrow} onClick={() => setSlideWeekNumber((prior) => (prior === 0 ? 6 : prior - 1))} />
              <div className="font-londrina text-mega text-center">{getDayOfWeek(slideWeekNumber)}</div>
              <img className="h-8" alt="text" src={RightArrow} onClick={() => setSlideWeekNumber((prior) => (prior === 6 ? 0 : prior + 1))} />
            </div>
          </div>
        </div>
        <div className="bg-white flex flex-col items-center pb-5">
        {
          weeklyMenu.find((menu) => menu.day == slideWeekNumber)
          ?
            weeklyMenu.filter(menu => menu.day == slideWeekNumber).map((m) => {
              return (
                <div className="flex flex-col items-center my-5">
                  <div className="text-center font-londrina text-lg">
                    {m.dish}
                  </div>
                  <div className="text-center w-11/12 font-sans font-width-90 font-medium text-sm">
                    {m.description}
                  </div>
                </div>
              ); }
            )
          :
            <div className="flex flex-col items-center my-5">
              <div className="text-center font-londrina text-lg">
                Gericht
              </div>
              <div className="text-center w-11/12 font-sans font-width-90 font-medium text-sm">
                Aktuell ist noch kein Gericht geplant. Bitte schau später noch einmal vorbei.
              </div>
            </div>
        }
        </div>

      </div>
      <div className="w-full flex">
        <img className="w-1/3" alt="text" src={Bildreihe4}></img>
        <img className="w-1/3" alt="text" src={Bildreihe5}></img>
        <img className="w-1/3" alt="text" src={Bildreihe6}></img>
      </div>
      <div className="flex flex-col items-center text-white mt-10">
        <div className="font-londrina text-mega text-center px-20 pb-3">
          ERBSENZÄHLEREI
          STEHT FÜR
          PERSONALISIERTE
          ERNÄHRUNG
        </div>
        <Disclosure>
          {({ open }) => (
            <div className="flex flex-col items-center justify-center">
              <div className="mt-3 text-center font-sans font-width-85 font-medium text-xs w-full">so funktioniert’s</div>
              <Disclosure.Button>
                <div className="w-full">
                  <img className={` ${open ? 'transform rotate-180' : ''
                    }  h-14 mb-5`} alt="text" src={DownArrow} />
                </div>
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel static>
                  <div className="flex flex-col items-center justify-center">
                    <div className="font-londrina text-xl text-center">
                      1. BESTELLTOOL ÖFFNEN
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      ggf. Unverträglichkeiten angeben
                    </div>

                    <div className="mt-10 font-londrina text-xl text-center">
                      2. GERICHT AUSSUCHEN
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      vegan oder nicht
                    </div>
                    <div className="mt-10 font-londrina text-xl text-center">
                      3. BESTELLEN
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      entweder eine
                    </div>
                    <div className="font-londrina text-lg text-center">
                      Standardportion
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      600 kcal, mit ausgewogener
                      Nährstoffzusammensetzung
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      oder eine
                    </div>
                    <div className="font-londrina text-lg text-center w-5/12">
                      personalisierte Portion
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      du bestimmst Kalorienmenge, Zutaten + Nährstoffzusammensetzung
                    </div>
                    <div className="mt-10 font-londrina text-lg text-center">
                      4. DEIN ESSEN
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      Aus deinen Angaben berechnen
                      wir deine Portion, wiegen alle
                      Zutaten grammgenau ab und
                      stellen dein ganz persönliches
                      Essen zusammen!
                    </div>

                    <div className="my-10">
                      <button
                        onClick={() => setAppLocation("gerichte")}
                        className="min-w-9 bg-buttonback text-gray-800 font-bold py-3 px-11 rounded-full items-center"
                      >
                        <span className="text-white text-xs font-sans font-medium font-width-85">
                          Jetzt bestellen
                        </span>
                      </button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </div>)}
        </Disclosure>
      </div>
      <div className="flex flex-col items-center bg-white py-5 w-full">
        <div className="mt-5 text-center font-londrina text-giga">WER WIR SIND</div>
        <div className="flex justify-around w-11/12 my-5">
          <div className="text-center font-londrina text-xl">
            <img className="h-32 w-32 rounded-full" src={Kirill} alt="Kirill"></img>
            <div className="my-3 w-32">KIRILL</div>
          </div>
          <div className="text-center font-londrina text-xl">
            <img className="h-32 w-32 rounded-full" src={John} alt="John"></img>
            <div className="my-3 w-32">JOHN</div>
          </div>
        </div>
        <div className="my-5 text-center w-10/12 font-sans font-width-90 font-medium text-sm">
          Wir sind zwei ziemlich
          unterschiedliche Menschen: Russe
          vs. Kanadier, 171 cm vs. 192 cm groß,
          Glatzkopf vs. Haarschönling, Single vs.
          Familienvater mit drei Kindern.
        </div>
        <div className="my-5 text-center w-10/12 font-sans font-width-90 font-medium text-sm">
          Was uns verbindet, ist die Liebe zum
          Essen und der Wunsch,etwas
          Sinnvolles zu tun. Nach vielen Jahren
          als Angestellte wollen wir etwas Eigenes auf die Beine stellen und mit der
          Erbsenzählerei unsere Leidenschaft
          für gesunde Ernährung und
          gutes Essen mit Euch teilen.
        </div>
      </div>
      <div className="flex flex-col items-center text-white my-10 ">
        <div className="font-londrina text-giga text-center">
          OUR MISSION:
        </div>
        <div className="my-3 text-center font-londrina font-width-90 text-xl w-9/12">
          simply personalicious
          Helping people stay healthy
          and have no regrets while
          eating out
        </div>
        <Disclosure>
          {({ open }) => (
            <div className="flex flex-col items-center justify-center">
              <Disclosure.Button>
                <div className="w-full">
                  <img className={` ${open ? 'transform rotate-180' : ''
                    }  h-14 mb-10`} alt="text" src={DownArrow} />
                </div>
              </Disclosure.Button>

              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel static>
                  <div className="flex flex-col items-center justify-center">
                    <div className="font-londrina text-xxxl text-center">
                      SORGENFREI
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      Wir nehmen dir mindestens eine
                      Entscheidung pro Tag ab. Damit
                      du dich vollständig auf deine
                      Aufgaben konzentrierst, ohne auf
                      gesundes und leckeres Essen
                      verzichten zu müssen
                    </div>

                    <div className="mt-10 font-londrina text-xxxl text-center">
                      PERSONALISIERT
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      Gesundes Essen = die richtige
                      Menge und Zusammensetzung!
                      Das mit Energiegehalt und Nährstoffverteilung übernehmen wir,
                      und du bekommst eine auf dich
                      zugeschnittene Portion.
                    </div>

                    <div className="mt-10 font-londrina text-xxxl text-center">
                      NAHRHAFT
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      Gesund = vollwertige Zutaten =
                      frische, möglichst naturbelassene
                      Lebensmittel. Ganz sicher ohne
                      Transfette, Farb-, Aroma-, Süßstoffe und alles Andere, was im guten
                      Essen nichts verloren hat.
                    </div>


                    <div className="mt-10 font-londrina text-xxxl text-center">
                      LECKER
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      Unser Geheimnis = Zeit, viel Zeit,
                      damit unser gesundes Essen dir
                      schmeckt und du auch beim Auswärts-Essen das Gefühl hast, am
                      Esstisch deiner Oma zu sitzen.
                    </div>

                    <div className="mt-10 font-londrina text-xxxl text-center">
                      NACHHALTIG
                    </div>
                    <div className="my-2 text-center font-sans font-width-90 font-medium text-xs w-9/12">
                      Nachhaltigkeit = für uns in jedem
                      Fall, nach Möglichkeit regional
                      und saisonal einzukaufen. Wenn
                      es geht, in Bio-Qualität. Und wiederverwendbare Behältnisse
                      aus Überzeugung.
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>

            </div>)}
        </Disclosure>

      </div>
      <div className="bg-dropback w-full flex justify-center">
        <Footer footerHomeLink={footerHomeLink} setAppLocation={setAppLocation} />
      </div>
    </div>
  );
};

export default Intro;
