import React, { useState, useEffect } from "react";
import Intro from "./Intro";
import Gerichte from "./Gerichte";
import PortionsGrosse from "./PortionsGrosse";
import Zutaten from "./Zutaten";
import Bestellung from "./Bestellung";
import PostBestellung from "./PostBestellung";
//import FoodExample from "./images/foodexample.jpg";
import StoneTexTile from "./images/StoneTexTile1000px.jpg";
import axios from "axios";
import Impressum from "./Impressum";
import Agb from "./Agb";

// print app as html string
//import ReactDOMServer from 'react-dom/server';
//console.log("App="+ReactDOMServer.renderToString(<App />));


function App() {
  const [appLocation, setAppLocation] = useState("intro");
  const [selectedMeal, setSelectedMeal] = useState(null);
  //const [selectedAllergy, setSelectedAllergy] = useState(["keine"]);
  const [selectedAllergy, setSelectedAllergy] = useState([]);
  const [selectedGender, setSelectedGender] = useState("male");
  const [selectedWeight, setSelectedWeight] = useState(50);
  const [selectedHeight, setSelectedHeight] = useState(150);
  const [selectedAge, setSelectedAge] = useState(18);
  const [selectedActivity, setSelectedActivity] = useState("moderate");
  const [caloryCalculation, setCaloryCalculation] = useState();
  const [customCaloryInput, setCustomCaloryInput] = useState();
  const [personalWidgetOpen, setPersonalWidgetOpen] = useState(false);
  const [pricePerKcal, setPricePerKcal] = useState(0.02);
  const [allMeals, setAllMeals] = useState([]);
  const [allAllergies, setAllAllergies] = useState([]);

  const getData = async () => {
    try {
      const allMealsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v0/daily_menu.php`
      );
      const mealRender = allMealsResponse.data.map((meal) => ({
        ...meal,
      }));
      setAllMeals(mealRender);

      const allAllergiesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v0/intolerances.php`
      );
      setAllAllergies([
        ...allAllergiesResponse.data,
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const resetAllVals = () => {
    setAllMeals([]);
    setAppLocation("gerichte");
    setSelectedMeal(null);
    //setSelectedAllergy(["keine"]);
    setSelectedAllergy([]);
    setSelectedGender("male");
    setSelectedWeight(50);
    setSelectedHeight(150);
    setSelectedAge(18);
    setSelectedActivity("moderate");
    setCaloryCalculation();
    setCustomCaloryInput();
    setPersonalWidgetOpen(false);
    getData();
  };

  const footerHomeLink = () => {
    setAllMeals([]);
    setAppLocation("intro");
    setSelectedMeal(null);
    //setSelectedAllergy(["keine"]);
    setSelectedAllergy([]);
    setSelectedGender("male");
    setSelectedWeight(50);
    setSelectedHeight(150);
    setSelectedAge(18);
    setSelectedActivity("moderate");
    setCaloryCalculation();
    setCustomCaloryInput();
    setPersonalWidgetOpen(false);
    getData();
  };

  // HGF: extend logging start
  //useEffect(() => console.log("App.js:selectedMeal=",selectedMeal), [selectedMeal]);
  //useEffect(() => console.log("App.js:selectedAllergy=",selectedAllergy), [selectedAllergy]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="flex min-h-screen"
      style={{ backgroundImage: `url(${StoneTexTile})` }}
    >
      {appLocation === "intro" && (
        <Intro
          setAppLocation={setAppLocation}
          footerHomeLink={footerHomeLink}
        />
      )}
      {appLocation === "gerichte" && (
        <Gerichte
          allMeals={allMeals}
          allAllergies={allAllergies}
          selectedAllergy={selectedAllergy}
          setSelectedAllergy={setSelectedAllergy}
          setAppLocation={setAppLocation}
          setSelectedMeal={setSelectedMeal}
          selectedMeal={selectedMeal}
          pricePerKcal={pricePerKcal}
        />
      )}
      {appLocation === "portionsgrosse" && (
        <PortionsGrosse
          personalWidgetOpen={personalWidgetOpen}
          setPersonalWidgetOpen={setPersonalWidgetOpen}
          setAppLocation={setAppLocation}
          setSelectedMeal={setSelectedMeal}
          selectedMeal={selectedMeal}
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
          selectedWeight={selectedWeight}
          setSelectedWeight={setSelectedWeight}
          selectedHeight={selectedHeight}
          setSelectedHeight={setSelectedHeight}
          selectedAge={selectedAge}
          setSelectedAge={setSelectedAge}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          caloryCalculation={caloryCalculation}
          setCaloryCalculation={setCaloryCalculation}
          customCaloryInput={customCaloryInput}
          setCustomCaloryInput={setCustomCaloryInput}
          pricePerKcal={pricePerKcal}
        />
      )}
      {appLocation === "zutaten" && (
        <Zutaten
          setAppLocation={setAppLocation}
          selectedMeal={selectedMeal}
          setSelectedMeal={setSelectedMeal}
          selectedAllergy={selectedAllergy}
        />
      )}
      {appLocation === "bestellung" && (
        <Bestellung
          setSelectedMeal={setSelectedMeal}
          setAppLocation={setAppLocation}
          selectedMeal={selectedMeal}
          footerHomeLink={footerHomeLink}
        />
      )}
      {appLocation === "postbestellung" && (
        <PostBestellung
          resetAllVals={resetAllVals}
          setAppLocation={setAppLocation}
          selectedMeal={selectedMeal}
          footerHomeLink={footerHomeLink}
        />
      )}
      {appLocation === "impressum" && (
        <Impressum
          resetAllVals={resetAllVals}
          setAppLocation={setAppLocation}
          appLocation={appLocation}
          footerHomeLink={footerHomeLink}
        />
      )}
      {appLocation === "agb" && (
        <Agb
          resetAllVals={resetAllVals}
          setAppLocation={setAppLocation}
          appLocation={appLocation}
          footerHomeLink={footerHomeLink}
        />
      )}
    </div>
  );
}

export default App;
