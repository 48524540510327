import React, { useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import Bowl from "./img/bowlIcon.svg";
import FemaleIcon from "./img/FimaleIconWHT.svg";
import FemaleIconRed from "./img/FimaleIconRed.svg";
import MaleIcon from "./img/MaleIconWht.svg";
import MaleIconRed from "./img/MaleIconRed.svg";
import { getVeganId, getIngredientIds, getEntrementIds } from './Utils';
//import QueerIcon from "./img/QueerIconWHT.svg";
//import QueerIconRed from "./img/QueerIconRed.svg";
//import Stack from "@mui/material/Stack";
//import Slider from "@mui/material/Slider";
//import { styled } from "@mui/material/styles";

/*
const VerticalSlider = styled(Slider)(() => ({
  height: "100px",
  color: "white",
  "& .MuiSlider-thumb": {
    color: "#952644",
    height: "12px",
    width: "12px",
    boxShadow: "none",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "none",
      "@media (hover: none)": {
        boxShadow: "none",
      },
    },
  },
  "& .MuiSlider-mark": {
    backgroundColor: "white",
    height: 3,
    width: 12,
    "&.MuiSlider-markActive": {
      opacity: 1,
      boxShadow: "none",
    },
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    width: 2,
  },
  "& .MuiSlider-track": {
    backgroundColor: "#952644",
    border: "none",
  },
}));
*/

const PortionsGrosse = ({
  selectedMeal,
  setSelectedMeal,
  setAppLocation,
  selectedGender,
  setSelectedGender,
  selectedWeight,
  setSelectedWeight,
  selectedHeight,
  setSelectedHeight,
  selectedAge,
  setSelectedAge,
  selectedActivity,
  setSelectedActivity,
  caloryCalculation,
  setCaloryCalculation,
  customCaloryInput,
  setCustomCaloryInput,
  personalWidgetOpen,
  setPersonalWidgetOpen,
  pricePerKcal,
}) => {
  console.log("3/5: PortionsGroesse.js");

  useEffect(() => {
    if (personalWidgetOpen) {
      let result;

      if (selectedGender === "male") {
        if (selectedActivity === "low") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.2) /
            3;
        } else if (selectedActivity === "light") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.375) /
            3;
        } else if (selectedActivity === "moderate") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.55) /
            3;
        } else {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.725) /
            3;
        }
      }

      if (selectedGender === "female") {
        if (selectedActivity === "low") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge -
              161) *
              1.2) /
            3;
        } else if (selectedActivity === "light") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge -
              161) *
              1.375) /
            3;
        } else if (selectedActivity === "moderate") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge -
              161) *
              1.55) /
            3;
        } else {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge -
              161) *
              1.725) /
            3;
        }
      }

      if (selectedGender === "queer") {
        if (selectedActivity === "low") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.2 +
              (10 * selectedWeight +
                6.25 * selectedHeight -
                5 * selectedAge -
                161) *
              1.2) /
            2 /
            3;
        } else if (selectedActivity === "light") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.375 +
              (10 * selectedWeight +
                6.25 * selectedHeight -
                5 * selectedAge -
                161) *
              1.375) /
            2 /
            3;
        } else if (selectedActivity === "moderate") {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.55 +
              (10 * selectedWeight +
                6.25 * selectedHeight -
                5 * selectedAge -
                161) *
              1.55) /
            2 /
            3;
        } else {
          result =
            ((10 * selectedWeight +
              6.25 * selectedHeight -
              5 * selectedAge +
              5) *
              1.725 +
              (10 * selectedWeight +
                6.25 * selectedHeight -
                5 * selectedAge -
                161) *
              1.725) /
            2 /
            3;
        }
      }

      setCaloryCalculation(Math.round(result));

      setSelectedMeal((current) => ({
        ...current,
        calories: { type: "personalized", value: Math.round(result) },
      }));
    }

    // eslint-disable-next-line
  }, [
    personalWidgetOpen,
    selectedWeight,
    selectedHeight,
    selectedAge,
    selectedGender,
    setSelectedMeal,
    selectedActivity,
  ]);

  //console.log("selectedActivity="+selectedActivity);

  return (
    selectedMeal && (
      <>
        {/* <div className="flex flex-col w-full mx-auto bg-appback rounded-xl min-h-screen"> */}
        {/* <div className="md:max-w-full"> */}
        <div className="w-full flex flex-col items-center">
          <div className="bg-dropback flex flex-col max-w-md mx-auto w-full">
            <div className="bg-dropback px-8 flex flex-col items-center ">
              <div className="bg-white rounded-b-2xl w-full max-w-md">
                <div className="p-4 flex justify-center">
                  <div className="text-xl font-londrina font-extrabold text-appback">
                    {selectedMeal.foodChoice.dish}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex bg-dropback py-4 items-center ">
              <div
                onClick={() => setAppLocation("gerichte")}
                className=" w-8 h-8 ml-3"
              >
                <ArrowLeftIcon className={`text-white cursor-pointer`} />
              </div>
              <div className="ml-2 flex verysmart:text-base text-[18px] font-sans font-bold font-width-90 text-white tracking-wide">
                3. PORTIONSGRÖSSE WÄHLEN
              </div>
            </div>

            <div className="bg-appback h-screen md:h-full smart:rounded-t-xl">
              <div className="pt-8 pb-6 px-6 flex flex-col items-center  bg-appback justify-center font-sans">
                <div className="w-full">
                  <label className="flex pt-2 items-center h-14 justify-between">
                    <div className="w-6 flex justify-center">
                      <input
                        onChange={() => {
                          setPersonalWidgetOpen(false);
                          setSelectedMeal((current) => ({
                            ...current,
                            calories: { type: "fixed", value: 400 },
                          }));
                        }}
                        className="w-2 h-2 text-transparent bg-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        checked={
                          selectedMeal.calories?.type === "fixed" &&
                            selectedMeal.calories?.value === 400
                            ? true
                            : false
                        }
                      />
                    </div>
                    <span className="text-white font-sm font-bold font-width-90 w-8 ml-2">
                      S
                    </span>
                    <span className="text-white text-tiny font-medium font-width-85 w-16">
                      400 kcal
                    </span>
                    <span className="w-20 ml-2">
                      <img className="h-10" src={Bowl} alt="Food" />
                    </span>
                    <span className="w-12 text-white font-sm font-bold font-width-90">
                      {Number.parseFloat(400 * pricePerKcal).toFixed(2)}€
                    </span>
                  </label>
                  <label className="flex pt-2 items-center h-14 justify-between">
                    <div className="w-6 flex justify-center">
                      <input
                        className="w-2 h-2 text-transparent bg-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        onChange={() => {
                          setPersonalWidgetOpen(false);
                          setSelectedMeal((current) => ({
                            ...current,
                            calories: { type: "fixed", value: 600 },
                          }));
                        }}
                        checked={
                          selectedMeal.calories?.type === "fixed" &&
                            selectedMeal.calories?.value === 600
                            ? true
                            : false
                        }
                      />
                    </div>
                    <span className="text-white font-sm font-bold font-width-90 w-8 ml-2">
                      M
                    </span>
                    <span className="text-white text-tiny font-medium font-width-85 w-16">
                      600 kcal
                    </span>
                    <span className="w-20 ml-2">
                      <img className="h-12" src={Bowl} alt="Food" />
                    </span>
                    <span className="w-12 text-white font-sm font-bold font-width-90">
                      {Number.parseFloat(600 * pricePerKcal).toFixed(2)}€
                    </span>
                  </label>
                  <label className="flex  pt-2 items-center h-14 justify-between">
                    <div className="w-6 flex justify-center">
                      <input
                        className="w-2 h-2 text-transparent bg-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        onChange={() => {
                          setPersonalWidgetOpen(false);
                          setSelectedMeal((current) => ({
                            ...current,
                            calories: { type: "fixed", value: 800 },
                          }));
                        }}
                        checked={
                          selectedMeal.calories?.type === "fixed" &&
                            selectedMeal.calories?.value === 800
                            ? true
                            : false
                        }
                        value={800}
                      />
                    </div>
                    <span className="text-white font-sm font-bold font-width-90 w-8 ml-2">
                      L
                    </span>
                    <span className="text-white text-tiny font-medium font-width-85 w-16">
                      800 kcal
                    </span>
                    <span className="w-20 ml-2">
                      <img className="h-14" src={Bowl} alt="Food" />
                    </span>
                    <span className="w-12 text-white font-sm font-bold font-width-90">
                      {Number.parseFloat(800 * pricePerKcal).toFixed(2)}€
                    </span>
                  </label>

                  <label className="flex pt-2 items-center h-14 justify-between relative">
                    <div className="w-6 flex justify-center">
                      <input
                        className="w-2 h-2 text-transparent bg-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        checked={
                          selectedMeal.calories?.type === "custom"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setPersonalWidgetOpen(false);
                          setSelectedMeal((current) => ({
                            ...current,
                            calories: {
                              type: "custom",
                              value:
                                e.target.value !== "" && e.target.value !== 0
                                  ? e.target.value
                                  : null,
                            },
                          }));
                        }}
                        value={customCaloryInput}
                      />
                    </div>
                    <span className="text-white font-bold w-8 ml-2">?</span>
                    <div className="relative">
                      <span className="w-16 flex">
                        {" "}
                        <input
                          className="w-14 bg-white font-sm font-bold font-width-90 outline-none border border-none rounded-sm placeholder-gray-400
                                    text-right text-gray-400 text-input-color py-0 px-1"
                          type="number"
                          onChange={(e) => {
                            setPersonalWidgetOpen(false);
                            setCustomCaloryInput(e.target.value);
                            setSelectedMeal((current) => ({
                              ...current,
                              calories: {
                                type: "custom",
                                value:
                                  e.target.value !== "" && e.target.value !== 0
                                    ? e.target.value
                                    : null,
                              },
                            }));
                          }}
                          value={customCaloryInput}
                          placeholder="0"
                        />
                      </span>
                      <div className="absolute text-tiny text-white font-medium font-width-85  w-56 left-0 top-10 text-left -mt-2">
                        eigene Kalorienmenge eingeben
                      </div>
                    </div>

                    <span className="w-20 ml-2 text-white text-tiny font-medium font-width-85">
                      kcal
                    </span>
                    <span className="w-12 text-white font-sm font-bold font-width-90">
                      {customCaloryInput
                        ? Number.parseFloat(
                          customCaloryInput * pricePerKcal
                        ).toFixed(2)
                        : "0,00"}
                      €
                    </span>
                  </label>




                  <label className="flex mt-5 pt-2 items-center h-14 justify-between">
                    <div className="w-6 flex justify-center">
                      <input
                        className="w-2 h-2 text-transparent bg-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        checked={
                          selectedMeal.calories?.type === "personalized"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setPersonalWidgetOpen(true);
                          setSelectedMeal((current) => ({
                            ...current,
                            calories: {
                              type: "personalized",
                              value: e.target.value,
                            },
                          }));
                        }}
                        value={caloryCalculation}
                      />
                    </div>
                    <span className="text-white font-sm font-bold font-width-90 w-8 ml-2">
                      PP
                    </span>
                    <span className="text-white text-tiny font-medium font-width-85 w-16 whitespace-nowrap">
                      Persönliche Portion nach deinen<br /> Angaben für dich berechnet
                    </span>
                    <span className="w-20 ml-2 ">

                    </span>
                    <span className="w-12 text-white font-sm font-bold font-width-90 ">

                    </span>
                  </label>
                  {personalWidgetOpen && (
                    <div className="pt-4 pb-2 font-sans text-xs font-medium font-width-85 text-apptextgray flex justify-center">
                      <div className="mt-2 flex flex-col">
                        <div className="flex">
                          <div className="w-4/6">
                            <div className="text-white text-tiny font-medium font-width-85">
                              Geschlecht
                            </div>
                            <div className="flex justify-between pr-5 border-r-2 border-gray-500">
                              <img
                                alt="maleicon"
                                className="h-10 mt-2"
                                src={
                                  selectedGender === "male"
                                    ? MaleIconRed
                                    : MaleIcon
                                }
                                onClick={() => setSelectedGender("male")}
                              />
                              <img
                                alt="femaleicon"
                                className="h-10 mt-2"
                                src={
                                  selectedGender === "female"
                                    ? FemaleIconRed
                                    : FemaleIcon
                                }
                                onClick={() => setSelectedGender("female")}
                              />
                            </div>
                            <div className="flex flex-col">
                              {" "}
                              <div className="grid grid-cols-3 justify-between py-3 pr-4 border-r-2 border-gray-500">
                                <span className="span-1 text-white text-tiny font-medium font-width-85 mr-2">
                                  Größe
                                </span>
                                <span className="span-1 flex">
                                  {" "}
                                  <input
                                    className="w-full h-5 bg-white font-sm font-bold font-width-90 outline-none border border-none rounded-sm placeholder-gray-400
                                    text-right text-gray-400 text-input-color py-0 px-1"
                                    type="number"
                                    placeholder="0"
                                    value={selectedHeight}
                                    onChange={(e) =>
                                      setSelectedHeight(e.target.value)
                                    }
                                    min="10"
                                    max="230"
                                  />
                                </span>
                                <span className="span-1 text-white text-tiny font-medium font-width-85 ml-2">
                                  cm
                                </span>
                              </div>
                              <div className="grid grid-cols-3 justify-between py-3 pr-4 border-r-2 border-gray-500">
                                <span className="span-1 text-white text-tiny font-medium font-width-85 mr-2">
                                  Alter
                                </span>
                                <span className="span-1 flex">
                                  {" "}
                                  <input
                                    className="w-full h-5 bg-white font-sm font-bold font-width-90 outline-none border border-none rounded-sm placeholder-gray-400
                                    text-right text-gray-400 text-input-color py-0 px-1"
                                    type="number"
                                    placeholder="0"
                                    value={selectedAge}
                                    onChange={(e) => {
                                      setSelectedAge(e.target.value);
                                    }}
                                    min="10"
                                    max="100"
                                  />
                                </span>
                                <span className="span-1 text-white text-tiny font-medium font-width-85 ml-2">
                                  Jahre
                                </span>
                              </div>
                              <div className="grid grid-cols-3 justify-between py-3 pr-4 border-r-2 border-gray-500">
                                <span className="span-1 text-white text-tiny font-medium font-width-85 mr-2">
                                  Gewicht
                                </span>
                                <span className="span-1 flex">
                                  {" "}
                                  <input
                                    className="w-full h-5 bg-white font-sm font-bold font-width-90 outline-none border border-none rounded-sm placeholder-gray-400
                                    text-right text-gray-400 text-input-color py-0 px-1"
                                    type="number"
                                    placeholder="0"
                                    value={selectedWeight}
                                    onChange={(e) => {
                                      setSelectedWeight(e.target.value);
                                    }}
                                    min="10"
                                    max="300"
                                  />
                                </span>
                                <span className="span-1 text-white text-tiny font-medium font-width-85 ml-2">
                                  Kg
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="w-2/6 ">
                            <div className="text-center ml-2 text-white text-tiny font-medium font-width-85">
                              Activity Level
                            </div>
                            <div className="flex flex-col justify-evenly h-full">
                              <div className="grid grid-cols-2">
                                <div className="w-12 flex items-center justify-center">
                                  <button
                                    onClick={() => setSelectedActivity("heavy")}
                                    className={`w-2 h-2 rounded-full text-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback ${selectedActivity === "heavy"
                                      ? "bg-white"
                                      : "bg-trasparent"
                                      } disabled`}
                                  />
                                </div>
                                <div className="flex items-center">
                                  <div className="text-white text-tiny font-medium font-width-85">
                                    heavy
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-2">
                                <div className="w-12 flex items-center justify-center">
                                  <button
                                    onClick={() =>
                                      setSelectedActivity("moderate")
                                    }
                                    className={`w-2 h-2 rounded-full text-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback ${selectedActivity === "moderate"
                                      ? "bg-white"
                                      : "bg-trasparent"
                                      } disabled`}
                                  />
                                </div>
                                <div className="flex items-center">
                                  <div className="text-white text-tiny font-medium font-width-85">
                                    moderate
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-2">
                                <div className="w-12 flex items-center justify-center">
                                  <button
                                    onClick={() => setSelectedActivity("light")}
                                    className={`w-2 h-2 rounded-full text-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback ${selectedActivity === "light"
                                      ? "bg-white"
                                      : "bg-trasparent"
                                      } disabled`}
                                  />
                                </div>
                                <div className="flex items-center">
                                  <div className="text-white text-tiny font-medium font-width-85">
                                    light
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-2">
                                <div className="w-12 flex items-center justify-center">
                                  <button
                                    onClick={() => setSelectedActivity("low")}
                                    className={`w-2 h-2 rounded-full text-transparent focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback ${selectedActivity === "low"
                                      ? "bg-white"
                                      : "bg-trasparent"
                                      } disabled`}
                                  />
                                </div>
                                <div className="flex items-center">
                                  <div className="text-white text-tiny font-medium font-width-85">
                                    low
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*                             <div className="flex flex-col items-center w-11">
                              <div className="text-white text-tiny pb-2">
                                {selectedActivity === 50
                                  ? "Light"
                                  : selectedActivity === 100
                                    ? "Moderate"
                                    : selectedActivity === 150
                                      ? "Heavy"
                                      : "Low"}
                              </div>
                              <VerticalSlider
                                min={0}
                                max={150}
                                step={50}
                                marks={[{ value: 150 }]}
                                aria-label="activity"
                                orientation="vertical"
                                value={selectedActivity}
                                onChange={(e) =>
                                  setSelectedActivity(e.target.value)
                                }
                              />

                              <div className="text-white leading-none text-tiny pt-2 flex text-center">
                                Activity Level
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div>
                          <div className="w-full flex pt-5 text-white text-tiny font-medium font-width-85 text-center justify-between">
                            <div className="w-1/2 mb-2 -ml-1">
                              <div className="ml-1 text-left text-tiny font-medium ">Persönliche Portion</div>
                              <div className="flex justify-between">
                                <div className="flex items-center justify-center ml-1">
                                  <div className="text-sm">PP</div>
                                  <div className="ml-2">=</div>
                                </div>
                                <div className="flex items-center justify-center">
                                  <div>
                                    {caloryCalculation && caloryCalculation > 0
                                      ? caloryCalculation
                                      : "0"}{" "}
                                    kcal
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-2/5 flex items-center justify-center">
                              <div className="font-bold p-2 w-full text-right mt-2">
                                {caloryCalculation && caloryCalculation > 0
                                  ? Number.parseFloat(
                                    caloryCalculation * pricePerKcal
                                  ).toFixed(2) + "€"
                                  : "0,00 €"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full bg-dropback mx-auto">
                <div className="bg-appback px-8 md:rounded-b-xl">
                  <div className="py-6 flex justify-center">
                    <button
                      onClick={() => setAppLocation("zutaten")}
                      className={`${!selectedMeal.calories?.value &&
                        "pointer-events-none opacity-30"
                        } bg-dropback w-56 text-white py-3 px-4 rounded-full flex items-center justify-center`}
                    >
                      <span className="font-sans text-xs font-medium font-width-85">
                        Weiter personalisieren
                      </span>
                    </button>
                  </div>
                  <div className="pb-2 flex justify-center">
                    <button
                      onClick={() => {
                        setSelectedMeal((current) => ({
                          ...current,
                          macroSplit: "balanced",
                          constraint: { fat: "30",carb: "55",protein: "15" },
                        }));
                        const intolerances = selectedMeal.foodChoice.allergies;
                        const did = selectedMeal.foodChoice.id;
                        const veganoption = selectedMeal.veganoption;
                        getIngredientIds(intolerances, did, veganoption).then( (ings) => {
                          setSelectedMeal( (current) => ({
                            ...current,
                            foodChoice: {
                              ...current.foodChoice,
                              ing_ids: ings.map((ing) => ing.id),
                            },
                          }));
                          getEntrementIds(intolerances, did, veganoption).then( (ents) => {
                            setSelectedMeal( (current) => ({
                              ...current,
                              foodChoice: {
                                ...current.foodChoice,
                                ent_ids: ents.map((ent) => ent.id),
                              },
                            }));
                            setAppLocation("bestellung");
                          });
                        });
                      }}
                      className={`${!selectedMeal.calories?.value &&
                        "pointer-events-none opacity-30"
                        } bg-buttonback w-56 py-3 px-4 rounded-full flex items-center justify-center`}
                    >
                      <span className="text-white font-sans text-xs font-medium font-width-85">
                        Express-Bestellung
                      </span>
                    </button>
                  </div>
                  <div className="flex flex-col items-center justify-center py-5">
                    <div className="text-apptextgray text-center text-tiny font-medium font-width-85 mb-4 ">
                      Ausgewogene Nährstoffverteilung mit Standardzutaten
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </>
    )
  );
};

export default PortionsGrosse;
