import React, { useEffect, useState } from "react";
import Loading from "./img/loading_640×360.gif";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import split1 from "./img/MacroSplit1.svg";
import split2 from "./img/MacroSplit2.svg";
import split3 from "./img/MacroSplit3.svg";
import split4 from "./img/MacroSplit4.svg";
import axios from "axios";

const Bestellung = ({
  setAppLocation,
  selectedMeal,
  setSelectedMeal,
  footerHomeLink,
}) => {
  const { t } = useTranslation();

  const [msg1, setMsg1] = useState();
  const [msg2, setMsg2] = useState();
  const [msg3, setMsg3] = useState();
  const [finalPrice, setFinalPrice] = useState();
  const [finalKcal, setFinalKcal] = useState();
  const [percentFat, setPercentFat] = useState();
  const [percentCarb, setPercentCarb] = useState();
  const [percentProtein, setPercentProtein] = useState();
  const [personalDishId, setPersonalDishId] = useState();
  const [personalDishOrderNumber, setPersonalDishOrderNumber] = useState();
  // pre-order details
  const [pickupHour, setPickupHour] = useState(11);
  const [pickupMinute, setPickupMinute] = useState("00");
  const [toGo, setToGo] = useState(1);
  const [payNow, setPayNow] = useState(0);
  // buttons
  const [loadingButton, setLoadingButton] = useState(true);
  const [orderFoodButton, setOrderFoodButton] = useState(false);


  useEffect(() => {
    const getPersonalDish = async () => {
      try {
        console.log("5/5: Bestellung.js did="+selectedMeal.foodChoice.id);
        //console.log("...selectedMeal.foodChoice.ing_ids=",...selectedMeal.foodChoice.ing_ids);
        //console.log("...selectedMeal.ing_ids=",...selectedMeal.ing_ids);
        //console.log("...selectedMeal.foodChoice.ent_ids",...selectedMeal.foodChoice.ent_ids);
        //console.log("...selectedMeal.ent_ids=",...selectedMeal.ent_ids);
        const personalDishResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v0/personal_dish.php`,
          {
            //TODO: suppe sind entremets!
            params: {
              did: selectedMeal.foodChoice.id,
              c_kcal: selectedMeal.calories.value,
              p_fat: selectedMeal.constraint.fat,
              p_carb: selectedMeal.constraint.carb,
              p_protein: selectedMeal.constraint.protein,
              // TODO (HGF): merge ing_ids on both levels. Analog ent_ids
              //ingredients: [...selectedMeal.foodChoice.ing_ids, ...selectedMeal.ing_ids],
              //entremets: [...selectedMeal.foodChoice.ent_ids, ...selectedMeal.ent_ids]
              ingredients: selectedMeal.foodChoice.ing_ids,
              entremets: selectedMeal.foodChoice.ent_ids,
              d_style: selectedMeal.veganoption,
              d_macro_split: selectedMeal.macroSplit,
            },
          }
        );
        //console.log("personalDishResponse="+personalDishResponse.data);
        if( !personalDishResponse.data.rc )
        {
          // personal dish calculation failed
          setLoadingButton(false);
          setMsg1("Online Bestellung nicht möglich.");
          setMsg2("Bitte im Bistro bestellen.");
          console.log("personal dish fail = "+personalDishResponse.data.msg);
        }
        else
        {
          // personal dish found
          setLoadingButton(false);
          setOrderFoodButton(true);
          setMsg1("Danke für die Bestellung!");
          setMsg2("Bestellnummer #"+personalDishResponse.data.pd.daily_order_id);
          setMsg3("Bestellstatus: Im Bistro zahlen!");
          setFinalPrice(
            Number.parseFloat(personalDishResponse.data.pd.price).toFixed(2)
          );
          setFinalKcal(
            Number.parseFloat(personalDishResponse.data.pd.kcal)
          );
          setPercentFat(
            Number.parseFloat(personalDishResponse.data.pd.percent_fat)
          );
          setPercentCarb(
            Number.parseFloat(personalDishResponse.data.pd.percent_carb)
          );
          setPercentProtein(
            Number.parseFloat(personalDishResponse.data.pd.percent_protein)
          );
          setPersonalDishId(
            Number.parseFloat(personalDishResponse.data.pd.id)
          );
          setPersonalDishOrderNumber(
            Number.parseFloat(personalDishResponse.data.pd.daily_order_id)
          );
        }
      }
      catch (err)
      {
        setLoadingButton(false);
        setMsg1("Leider ist ein Fehler aufgetreten!");
        setMsg2(err);
        console.error("personal_dish error = "+err);
      }
    };
    getPersonalDish();
  }, []);

  useEffect(() => {
    setSelectedMeal((current) => ({
      ...current,
      ...(selectedMeal.hasOwnProperty("macroSplit") === false && {
        macroSplit: "balanced",
      }),
      ...(selectedMeal.hasOwnProperty("extras") === false && {
        extras: [],
      }),
      ...(selectedMeal.hasOwnProperty("calories") === false && {
        calories: {
          type: "fixed",
          value: 600,
        },
      }),
    }));
    // eslint-disable-next-line
  }, []);

  // TODO change by HGF
  const submitOrder = async () => {
    try {
      console.log("pickupHour="+pickupHour+" pickupMinute="+pickupMinute+" toGo="+toGo+" payNow="+payNow);

      // 2. insert payment into database
      const orderResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v0/order.php`,
        {
          params: {
            pdid: personalDishId,
            pdOrderNumber: personalDishOrderNumber,
            payNow: payNow,
            pickupHour: pickupHour,
            pickupMinute: pickupMinute,
            toGo: toGo,
            price: finalPrice
          },
        }

      );

      if ( orderResponse.data.rc ) {
        if ( orderResponse.data.url ) {
          // forwar to online payment
          window.location.href = orderResponse.data.url;
        } else {
          // show booking on this page
          setOrderFoodButton(false);
          //console.log("personal dish ordered with pdid "+personalDishId);
          //console.log("orderStatus = "+orderResponse.data.orderStatus);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  /*
  useEffect(() => {
    if (selectedMeal?.calories?.value) {
      setFinalPrice(
        Number.parseFloat((selectedMeal.calories.value * 1.5) / 100).toFixed(2)
      );
    }
  }, [selectedMeal?.calories?.value]);
  */
  return (
    selectedMeal.hasOwnProperty("calories") &&
    selectedMeal.hasOwnProperty("extras") &&
    selectedMeal.hasOwnProperty("macroSplit") && (
      <div className="w-full flex flex-col items-center">
        {/* <div className="flex flex-col w-full min-h-full"> */}
        {/* <div className="flex flex-col bg-appback rounded-xl h-full"> */}
        <div className="flex flex-col max-w-md  mx-auto flex-1 w-full">
          <div className="flex bg-dropback py-4 items-center">
            <div
              onClick={() => setAppLocation("zutaten")}
              className=" w-8 h-8 ml-3"
            >
              <ArrowLeftIcon className={`text-white cursor-pointer`} />
            </div>
            <div className="ml-2 flex text-white font-bold font-width-90 items-center tracking-wide verysmart:text-base text-[18px]">
              <div>6.</div>
              <div className="ml-2">BESTELLUNG ABSCHLIESSEN</div>
            </div>
          </div>
          <div className="w-full flex flex-col items-center flex-1 text-whiteback">
            <div className="py-4 px-8 bg-appback w-full h-full">
              <div className="py-4 flex justify-center">
                <div className="rounded-2xl max-w-sm overflow-hidden shadow-lg w-full relative">
                  <div
                    className="h-72 bg-cover rounded-2xl bg-no-repeat bg-center absolute w-full  inset-x-0 top-0 "
                    style={{
                      backgroundImage: `url('${
                        process.env.REACT_APP_API_URL +
                        selectedMeal.foodChoice.img
                      }')`,
                    }}
                  ></div>
                  {/*                   <div className="h-24 overflow-hidden">
                    <img className="w-full" src={`${process.env.REACT_APP_API_URL + selectedMeal.foodChoice.img}`} alt="Food" />
                  </div>  */}
                  <div className="bg-white">
                    <div className="pt-72 pb-4 px-4">
                      <div className="flex flex-col text-lg font-londrina font-extrabold mt-4">
                        {selectedMeal.foodChoice.dish}
                      </div>
                      <ul className="list-disc text-xs p-4">
                        {selectedMeal.extras?.map((extra, ind) => (
                          <li key={ind}>{t(extra)}</li>
                        ))}
                      </ul>
                      <div className="pb-2 font-sans font-width-90">
                        <span className="text-base font-semibold">
                          PORTION:{" "}
                        </span>
                        <span className="text-sm pb-4">
                          {t(selectedMeal.veganoption)}
                        </span>
                      </div>
                      <div className="pb-2 font-sans font-width-90">
                        <span className="text-base font-semibold">
                          MACRO SPLIT:{" "}
                        </span>
                        <span className="text-sm pb-4">
                          {selectedMeal.macroSplit}
                        </span>
                      </div>
                      <label className="flex p-4 h-16 items-center justify-between mt-5">
                        <span className="text-whiteback leading-5 text-base w-12 font-sans font-bold font-width-90">
                          {/*selectedMeal.calories?.value*/}
                          {finalKcal} kcal
                        </span>
                        <div className="mr-2">
                          <span className="text-whiteback flex items-end text-xs transform scale-x-130 scale-y-125 mr-4">
                            {selectedMeal.macroSplit === "balanced" && (
                              <>
                                <span className="text-whiteback flex items-end text-litletiny font-light font-width-75">
                                  <div className="flex flex-col w-10 items-center relative mr-1">
                                    <div className="absolute bottom-6 break-normal px-3">
                                      {t("Fats")}
                                    </div>
                                    <img
                                      alt="split3"
                                      className="w-10 h-10 transform scale-x-130 scale-y-125"
                                      src={split3}
                                    />
                                    <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                      {percentFat} %
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-10 items-center relative">
                                    <div className="absolute bottom-6 break-normal px-3 leading-none">
                                      {t("Carbo- hydrates")}
                                    </div>
                                    <img
                                      alt="split3"
                                      className="w-10 h-10 transform scale-x-130 scale-y-125"
                                      src={split3}
                                    />
                                    <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                      {percentCarb} %
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-10 items-center relative ml-1">
                                    <div className="absolute bottom-6 break-normal px-3">
                                      {t("Proteins")}
                                    </div>
                                    <img
                                      alt="split1"
                                      className="w-10 h-10 transform scale-x-130 scale-y-125"
                                      src={split1}
                                    />
                                    <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                      {percentProtein} %
                                    </div>
                                  </div>
                                </span>
                              </>
                            )}
                            {selectedMeal.macroSplit === "high protein" && (
                              <span className="text-whiteback flex flex-1 items-end text-litletiny font-light font-width-75">
                                <div className="flex flex-col w-10 items-center relative mr-1">
                                  <div className="absolute bottom-6 break-normal px-3">
                                    {t("Fats")}
                                  </div>
                                  <img
                                    alt="split2"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split2}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentFat} %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Carbo- hydrates")}
                                  </div>
                                  <img
                                    alt="split2"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split2}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentCarb} %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative ml-1">
                                  <div className="absolute bottom-6 break-normal px-3">
                                    {t("Proteins")}
                                  </div>
                                  <img
                                    alt="split4"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split4}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentProtein} %
                                  </div>
                                </div>
                              </span>
                            )}
                            {selectedMeal.macroSplit === "low fat" && (
                              <span className="text-whiteback flex flex-1 items-end text-litletiny font-light font-width-75">
                                <div className="flex flex-col w-10 items-center relative mr-1">
                                  <div className="absolute bottom-6 break-normal px-3">
                                    {t("Fats")}
                                  </div>
                                  <img
                                    alt="split1"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split1}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentFat} %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Carbo- hydrates")}
                                  </div>
                                  <img
                                    alt="split4"
                                    t="split4"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split4}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentCarb} %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative ml-1">
                                  <div className="absolute bottom-6 break-normal px-3">
                                    {t("Proteins")}
                                  </div>
                                  <img
                                    alt="split2"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split2}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentProtein} %
                                  </div>
                                </div>
                              </span>
                            )}
                            {selectedMeal.macroSplit === "low carb" && (
                              <span className="text-appback flex flex-1 items-end text-litletiny font-light font-width-75">
                                <div className="flex flex-col w-10 items-center relative mr-1">
                                  <div className="absolute bottom-6 break-normal px-3">
                                    {t("Fats")}
                                  </div>
                                  <img
                                    alt="split4"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split4}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentFat} %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Carbo- hydrates")}
                                  </div>
                                  <img
                                    alt="split1"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split1}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentCarb} %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative ml-1">
                                  <div className="absolute bottom-6 break-normal px-3">
                                    {t("Proteins")}
                                  </div>
                                  <img
                                    alt="split3"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split3}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    {percentProtein} %
                                  </div>
                                </div>
                              </span>
                            )}
                          </span>
                        </div>
                      </label>
                    </div>

                    <div className="p-4 flex w-full justify-end">
                      <span className="text-base font-bold font-width-90 leading-5">
                        {" "}
                        {finalPrice} €
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-8 pt-4 flex justify-center w-full">
                {orderFoodButton ? (
                  <div>
                    <div className="bg-dropback">

                      <div className="flex flex-col justify-center py-6">
                        <div className="flex items-center">
                          <div className="w-8 h-8 ml-3" ></div>
                          <div className="ml-2 verysmart:text-base text-[18px] text-white font-bold font-width-90 tracking-wide">
                            Bestellen  <br />
                            <span className="text-tiny text-white font-medium font-width-85 h-10">
                              Verbindlich bestellen und gleich Zahlen mit Zeitgarantie,
                              oder einfach nur vorbestellen (ohne Zeitgarantie).
                            </span>
                          </div>
                        </div>
                        <div className="pr-4 flex h-full items-center justify-center"></div>
                      </div>

                      <div className="flex flex-col justify-center py-2">
                        <div className="flex items-center">
                          <div className="w-8 h-8 ml-3" ></div>
                          <div className="ml-2 text-white font-bold">
                            Abholzeit?
                            <select onChange={(e) => setPickupHour(e.target.value)} name="hour" id="hour" className="ml-2 text-black">
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                            </select>
                            <select onChange={(e) => setPickupMinute(e.target.value)} name="minute" id="minute" className="ml-2 text-black">
                              <option value="00">00</option>
                              <option value="05">05</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="30">30</option>
                              <option value="35">35</option>
                              <option value="40">40</option>
                              <option value="45">45</option>
                              <option value="50">50</option>
                              <option value="55">55</option>
                            </select>
                          </div>
                        </div>
                        <div className="pr-4 flex h-full items-center justify-center"></div>
                      </div>

                      <div className="flex flex-col justify-center py-2">
                        <div className="flex items-center">
                          <div className="w-8 h-8 ml-3" ></div>
                          <div className="ml-2 text-white font-bold">
                            <span className="text-tiny text-white font-medium font-width-85 h-10">
                              <label onClick={(e) => setToGo(e.target.value)} className="inline-flex items-center ml-3 verysmart:ml-0">
                                <input type="radio"  name="toGo" value="1" defaultChecked={toGo === 1} className="text-buttonback bg-transparent focus:border-transparent border-transparent ring-black ring-1 focus:ring-black focus:ring-1"/>
                                <span className="ml-2 text-tany font-blackjack">
                                  Zum Mitnehmen
                                </span>
                              </label>
                            </span>
                          </div>
                          <div className="ml-2 text-white font-bold">
                            <span className="text-tiny text-white font-medium font-width-85 h-10">
                              <label onClick={(e) => setToGo(e.target.value)} className="inline-flex items-center ml-3 verysmart:ml-0">
                                <input type="radio" name="toGo" value="0" defaultChecked={toGo === 0} className="text-buttonback bg-transparent focus:border-transparent border-transparent ring-black ring-1 focus:ring-black focus:ring-1" />
                                <span className="ml-2 text-tany font-blackjack">
                                  Im Bistro
                                </span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="pr-4 flex h-full items-center justify-center"></div>
                      </div>

                      <div className="flex flex-col justify-center py-2">
                        <div className="flex items-center">
                          <div className="w-8 h-8 ml-3" ></div>
                          <div className="ml-2 text-white font-bold">
                            <span className="text-tiny text-white font-medium font-width-85 h-10">
                              <label onClick={(e) => setPayNow(e.target.value)} className="inline-flex items-center ml-3 verysmart:ml-0">
                                <input type="radio" name="payNow" value="0" defaultChecked={payNow === 0} className="text-buttonback bg-transparent focus:border-transparent border-transparent ring-black ring-1 focus:ring-black focus:ring-1" />
                                <span className="ml-2 text-tany font-blackjack">
                                  Unverbindlich
                                </span>
                              </label>
                            </span>
                          </div>
                          <div className="ml-2 text-white font-bold">
                            <span className="text-tiny text-white font-medium font-width-85 h-10">
                              <label onClick={(e) => setPayNow(e.target.value)} className="inline-flex items-center ml-3 verysmart:ml-0">
                                <input type="radio" name="payNow" value="1" defaultChecked={payNow === 1} className="text-buttonback bg-transparent focus:border-transparent border-transparent ring-black ring-1 focus:ring-black focus:ring-1" />
                                <span className="ml-2 text-tany font-blackjack">
                                  Verbindlich
                                </span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="pr-4 flex h-full items-center justify-center"></div>
                      </div>

                      <div className="flex flex-col justify-center py-2">
                        <div className="flex items-center">
                          <div className="w-8 h-8 ml-3" ></div>
                          <div className="ml-2 text-white font-bold">
                            <button
                              onClick={() => submitOrder()}
                              className="text-white font-sans text-xs font-medium font-width-85 bg-buttonback w-56 py-3 px-4 rounded-full flex items-center justify-center">
                              Bestellen
                            </button>
                          </div>
                        </div>
                        <div className="pr-4 flex h-full items-center justify-center"></div>
                      </div>

                    </div> {/* bg-dropback */}
                  </div>
                ) : ( loadingButton ? (
                  <div className="flex flex-col items-center justify-center mb-5">
                    <div className="text-apptextgray text-base  font-sans font-width-85">
                      <img className="h-full" alt="text" src={Loading} width="160" height="90"/>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mb-5">
                    <div className="text-apptextgray text-base font-sans font-width-85">
                      {msg1} <br />
                      {msg2} <br />
                      {msg3} <br />
                      {personalDishId ? (
                        <div>
                        Abholzeit: {pickupHour}:{pickupMinute} <br />
                        Essen: { (toGo==0) ? "Im Bistro" : "Zum Mitnehmen"} <br />
                        ID: {personalDishId}
                        </div>
                      ) : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="w-full  flex justify-center">
          <Footer
            footerHomeLink={footerHomeLink}
            setAppLocation={setAppLocation}
          />
        </div>
        {/* </div> */}
      </div>
    )
  );
};

export default Bestellung;
