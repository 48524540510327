import React, { useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { getVeganId, getIngredientIds, getEntrementIds } from './Utils';

const Gerichte = ({
  setSelectedMeal,
  selectedMeal,
  setAppLocation,
  selectedAllergy,
  setSelectedAllergy,
  allMeals,
  allAllergies,
  pricePerKcal,
}) => {

  // HGF logging start
  console.log("2/5: Gericht.js");
  //useEffect(() => console.log(selectedMeal), [selectedMeal]);
  //useEffect(() => console.log(selectedAllergy), [selectedAllergy]);
  // HGF logging end
  /*
  useEffect(
    //() => selectedAllergy.length === 0 && setSelectedAllergy(["keine"]),
    () => selectedAllergy.length === 0 && setSelectedAllergy([]),
    // eslint-disable-next-line
    [selectedAllergy]
  );
  */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() =>{
    if (selectedMeal !== null){
      window.scrollTo(0,document.body.scrollHeight, "slow")
    }
  },[selectedMeal])

  /**
  * Meal is not allowed if one mandatory ingredient or entremets is
  * an intolerance against a selected allergy. This means the guest
  * cannot order the meal.
  **/
  function isMealForbidden(foodChoice) {
    // compare mandatory intolerances of fooChoice with
    // selected allergies of users and if the match the meal is forbidden
    let isForbidden = false;
    foodChoice.allergies.map((allergy) =>
      selectedAllergy.map(
        (sa) => sa.intolerance === allergy ?
          isForbidden=true : 0
      )
    )
    return isForbidden;
  };

  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col items-center">
      <div className="bg-appback flex flex-col max-w-md mx-auto  w-full">
        <div className="bg-dropback overflow-hidden px-8 flex flex-col items-center">
          <div className="bg-white rounded-b-2xl w-full max-w-md">
            <div className="p-4 flex justify-center">
              <div className="text-xl font-londrina font-extrabold text-appback">
                Speisekarte
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-dropback mx-auto  border-linedivide mb-0.5">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  as="div"
                  className="w-full px-4 py-4 text-left text-white flex justify-between"
                >
                  <span className="verysmart:text-base text-[18px] font-sans font-bold font-width-90  tracking-wide">
                    1. {t("ALLERGIES / INTOLERANCES?")}
                  </span>

                  <ChevronDownIcon
                    className={`col-start-12 ${
                      open ? "transform rotate-180" : ""
                    } w-6 h-6 text-white`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="py-4 px-8 bg-appback text-white text-xs font-sans font-medium font-width-90">
                    {allAllergies.map((allergy, ind) => (
                      <div key={ind} className="py-1 my-0.5">
                        <label className="inline-flex items-center">
                          <div className="round flex">
                            <input
                              onChange={(e) => {
                                // set selected allergies (id and name)
                                setSelectedAllergy((prior) =>
                                  e.target.checked === false
                                    ? selectedAllergy.filter(
                                        (item) => item !== allergy
                                      )
                                    : allergy.intolerance === []
                                    ? []
                                    : [...prior, allergy].filter(
                                        (allergyfilter) =>
                                          allergyfilter !== []
                                      )
                                );
                                // set selected allergies (name)
                                /*
                                setSelectedAllergy((prior) =>
                                  e.target.checked === false
                                    ? selectedAllergy.filter(
                                        (item) => item !== allergy.intolerance
                                      )
                                    : allergy.intolerance === "keine"
                                    ? ["keine"]
                                    : [...prior, allergy.intolerance].filter(
                                        (allergyfilter) =>
                                          allergyfilter !== "keine"
                                      )
                                );
                                */

                                // TODO: HGF trying to add selected allergies
                                //console.log("set selectedAllergy="+selectedAllergy);
                                //foodChoice.allergies = selectedAllergies;
                                //setSelectedAllergy(selectedAllergies);
                                // HGF end

                              }}
                              type="checkbox"
                              checked={
                                // check if allergy is set or not (id and name)
                                selectedAllergy.filter(
                                  (item) =>
                                    item.id == allergy.id
                                ).length > 0 ? true : false

                                // Validate if allergy is set (name)
                                /*
                                selectedAllergy.filter(
                                  (item) => item === allergy.intolerance
                                ).length > 0
                                  ? true
                                  : false
                                */
                              }
                              id={ind}
                            />
                            <label htmlFor={ind}></label>
                          </div>
                          <span className="ml-4">
                            {allergy.intolerance}
                          </span>
                        </label>
                        {ind % 4 === 0 && (
                          <>
                            <div className="h-5 border-b-2 border-linedivide" />
                            <div className="h-5" />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className="w-full bg-dropback mx-auto  border-linedivide">
          <div className="w-full px-4 py-4 text-left text-white flex flex-col">
            <span className="verysmart:text-base text-[18px] font-sans font-bold font-width-90  tracking-wide">
              2. GERICHT WÄHLEN
            </span>
            <div className="px-4 mx-1 verysmart:px-6 verysmart:mx-0 my-2 text-white verysmart:text-base text-[16px]  font-sans font-medium font-width-85">
              mit den "vegan", "vegetarisch" oder "fleischhaltig" Buttons wählst Du ein Gericht
              aus.
            </div>
          </div>
        </div>

        <div className="bg-appback md:mb-5">
          <div className="flex flex-col items-center justify-center px-14">
            <div className="mt-8 mb-1 text-white text-center text-sm font-semibold font-sans font-width-90">
              {t("All Dishes")} ab {Number.parseFloat(400 * pricePerKcal).toFixed(2)} €
            </div>
            <div className="mb-1 text-apptextgray text-sm font-sans font-medium text-center font-width-85">
              Pay what you eat:{" "}
              {t("We calculate the price depending on the portion size")}{" "}
            </div>
            <div className="text-white mb-4 text-sm font-semibold font-sans font-width-90">
              {Number.parseFloat(pricePerKcal * 100).toFixed(2)} ct/Kcal
            </div>
          </div>

          <div className="px-8">
            {allMeals.map((foodChoice, ind) => (
              <div
                key={ind}
                className={`py-4 ${
                  isMealForbidden(foodChoice) && "pointer-events-none "
                }`}
              >
                <div className="rounded-2xl overflow-hidden shadow-lg max-w-full z-10 relative">
                  <img
                    className={`w-full ${
                      isMealForbidden(foodChoice) && "pointer-events-none opacity-30"
                    }`}
                    src={`${process.env.REACT_APP_API_URL + foodChoice.img}`}
                    alt={foodChoice.description}
                  />
                  <div className="bg-white">
                    <div
                      className={`p-4 ${
                        isMealForbidden(foodChoice) && "pointer-events-none opacity-30 "
                      }`}
                    >
                      <div className="text-appback text-lg font-londrina font-extrabold">
                        {foodChoice.dish}
                      </div>
                      <p className="text-appback text-sm font-sans font-medium font-width-85">
                        {foodChoice.description}
                      </p>
                      <div className="flex w-full justify-between  pt-3">
                        {foodChoice.nutrition_style.map((nutrstyle) => (
                          <label
                            onClick={() =>
                              setSelectedMeal({
                                foodChoice,
                                veganoption: nutrstyle,
                              })
                            }
                            className="inline-flex items-center ml-3 verysmart:ml-0 vegetarian"
                          >
                            <input
                              type="radio"
                              className="text-buttonback bg-transparent focus:border-transparent border-transparent ring-black ring-1 focus:ring-black focus:ring-1"
                              name="accountType"
                              value={nutrstyle}
                              defaultChecked={
                                selectedMeal !== null
                                  ? selectedMeal.foodChoice.id ===
                                      foodChoice.id &&
                                    selectedMeal.veganoption === nutrstyle
                                    ? true
                                    : false
                                  : false
                              }
                            />
                            <span className="ml-2 text-tany font-blackjack">
                              {nutrstyle}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="py-4 flex justify-center">
              <button
                onClick={() => {
                  setSelectedMeal( (current) => ({
                    ...current,
                    foodChoice: {
                      ...current.foodChoice,
                      allergies: selectedAllergy.map((sa) => (sa.id))
                    },
                  }));
                  setAppLocation("portionsgrosse");
                }}
                className={`${
                  !selectedMeal && "pointer-events-none opacity-30"
                } font-sans text-xs font-medium font-width-85 bg-dropback w-56 text-white py-3 px-4 rounded-full flex items-center justify-center`}
              >
                <span>Portionsgrösse wählen</span>
              </button>
            </div>
            <div className="pb-2 flex justify-center">
              <button
                onClick={() => {
                  setSelectedMeal((current) => ({
                    ...current,
                    calories: { type: "fixed", value: 600 },
                    macroSplit: "balanced",
                    constraint: { fat: "30",carb: "55",protein: "15" },
                  }));
                  const intolerances = selectedAllergy.map((sa) => (sa.id));
                  const did = selectedMeal.foodChoice.id;
                  const veganoption = selectedMeal.veganoption;
                  getIngredientIds(intolerances, did, veganoption).then( (ings) => {
                    setSelectedMeal( (current) => ({
                      ...current,
                      foodChoice: {
                        ...current.foodChoice,
                        ing_ids: ings.map((ing) => ing.id),
                      },
                    }));
                    getEntrementIds(intolerances, did, veganoption).then( (ents) => {
                      setSelectedMeal( (current) => ({
                        ...current,
                        foodChoice: {
                          ...current.foodChoice,
                          ent_ids: ents.map((ent) => ent.id),
                        },
                      }));
                      setAppLocation("bestellung");
                    });
                  });
                }}
                className={`${
                  !selectedMeal && "pointer-events-none opacity-30"
                } bg-buttonback w-56 text-black py-3 px-4 rounded-full flex items-center justify-center`}
              >
                <span className="text-white font-sans text-xs font-medium font-width-85">
                  Express-Bestellung
                </span>
              </button>
            </div>
            <div className="flex flex-col items-center justify-center mb-5">
              <div className="mb-1 text-apptextgray text-xs font-sans font-width-85">
                {t("Standard portion with")} 600 kcal{" "}
              </div>
              <div className="text-apptextgray text-base  font-sans font-width-85">
                {Number.parseFloat(600 * pricePerKcal).toFixed(2)} €
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gerichte;
