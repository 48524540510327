import axios from "axios";

/*******************************************************************************
* Simple functions
*******************************************************************************/

export function getVeganId(veganoption)
{
  if ( veganoption === "vegan" ) return 0;
  else if ( veganoption === "vegetarisch" ) return 1;
  else if ( veganoption === "fleischhaltig" ) return 2;
  else return null;
};

/*******************************************************************************
* Async functions
*******************************************************************************/

/**
* Return array of ingredient ids
*/
export async function getIngredientIds(intolerances, did, veganoption)
{
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/v0/filtered_dish_ingredients.php`,
    {
      params: {
        did: did,
        ns: getVeganId(veganoption),
        intolerances: intolerances
      },
    }
  ).then( (e) => {
    return e.data;
  });
};

/**
* Return array of entrements ids
*/
export async function getEntrementIds(intolerances, did, veganoption)
{
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/v0/filtered_dish_entremets.php`,
    {
      params: {
        did: did,
        ns: getVeganId(veganoption),
        intolerances: intolerances
      },
    }
  ).then( (e) => {
    return e.data;
  });
};
