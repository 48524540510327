import React from "react";
//import FoodExample from "./images/foodexample.jpg";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import split1 from "./img/MacroSplit1.svg";
import split2 from "./img/MacroSplit2.svg";
import split3 from "./img/MacroSplit3.svg";
import split4 from "./img/MacroSplit4.svg";

const PortionsGrosse = ({
  selectedMeal,
  resetAllVals,
  footerHomeLink,
  setAppLocation,
}) => {
  const { t } = useTranslation();

  // TODO changed by HGF
  console.log("HGF: PostBestellung.js");

  return (
    <>
      <div className="w-full flex flex-col items-center">
        <div className="flex flex-col max-w-md mx-auto w-full">
          <div className="bg-dropback py-4 flex justify-center">
            <div className="col-span-12 font-sans text-base text-white font-bold font-width-90 tracking-wide">
              {t("ORDER CODE")} #435
            </div>
          </div>
          <div className="flex flex-col max-w-md h-full">
            <div className="bg-appback h-full">
              {/* <div className="flex flex-col items-center"> */}
              <div className="py-4 px-8">
                <div className="py-4 flex justify-center">
                  <div className="rounded-2xl max-w-sm overflow-hidden shadow-lg w-full relative">
                    <div
                      className="h-72 bg-cover rounded-2xl bg-no-repeat bg-center absolute w-full  inset-x-0 top-0 "
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_API_URL +
                          selectedMeal.foodChoice.img
                        })`,
                      }}
                    ></div>
                    {/*                   <div className="h-24 overflow-hidden">
                    <img className="w-full" src={`${process.env.REACT_APP_API_URL + selectedMeal.foodChoice.img}`} alt="Food" />
                  </div>  */}
                    <div className="bg-white">
                      <div className="pt-72 pb-4 px-4">
                        <div className="flex flex-col text-lg font-londrina font-extrabold mt-4">
                          {selectedMeal.foodChoice.dish}
                        </div>
                        <ul className="list-disc text-xs p-4">
                          {selectedMeal.extras?.map((extra, ind) => (
                            <li key={ind}>{t(extra)}</li>
                          ))}
                        </ul>
                        <div className="pb-2 font-sans font-width-90">
                          <span className="text-base font-semibold">
                            PORTION:{" "}
                          </span>
                          <span className="text-sm pb-4">
                            {t(selectedMeal.veganoption)}
                          </span>
                        </div>
                        <div className="pb-2 font-sans font-width-90">
                          <span className="text-base font-semibold">
                            MACRO SPLIT:{" "}
                          </span>
                          <span className="text-sm pb-4">
                            {selectedMeal.macroSplit}
                          </span>
                        </div>
                        <label className="flex p-4 h-16 items-center justify-between mt-5">
                          <span className="text-whiteback leading-5 text-base w-12 font-sans font-bold font-width-90">
                            {selectedMeal.calories?.value} kcal
                          </span>
                          <span className="text-whiteback flex items-end text-xs transform scale-x-130 scale-y-125 mr-4">
                            {selectedMeal.macroSplit === "balanced" && (
                              <>
                                <span className="text-whiteback flex items-end text-litletiny font-light font-width-75">
                                  <div className="flex flex-col w-10 items-center relative mr-1">
                                    <div className="absolute bottom-6 break-normal px-3 leading-none">
                                      {t("Fats")}
                                    </div>
                                    <img
                                      alt="split3"
                                      className="w-10 h-10 transform scale-x-130 scale-y-125"
                                      src={split3}
                                    />
                                    <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                      30 %
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-10 items-center relative">
                                    <div className="absolute bottom-6 break-normal px-3 leading-none">
                                      {t("Carbo- hydrates")}
                                    </div>
                                    <img
                                      alt="split3"
                                      className="w-10 h-10 transform scale-x-130 scale-y-125"
                                      src={split3}
                                    />
                                    <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                      55 %
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-10 items-center relative ml-1">
                                    <div className="absolute bottom-6 break-normal px-3 leading-none">
                                      {t("Proteins")}
                                    </div>
                                    <img
                                      alt="split1"
                                      className="w-10 h-10 transform scale-x-130 scale-y-125"
                                      src={split1}
                                    />
                                    <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                      15 %
                                    </div>
                                  </div>
                                </span>
                              </>
                            )}
                            {selectedMeal.macroSplit === "high protein" && (
                              <span className="text-whiteback flex flex-1 items-end text-litletiny font-light font-width-75">
                                <div className="flex flex-col w-10 items-center relative mr-1">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Fats")}
                                  </div>
                                  <img
                                    alt="split2"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split2}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    20 %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Carbo- hydrates")}
                                  </div>
                                  <img
                                    alt="split2"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split2}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    45 %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative ml-1">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Proteins")}
                                  </div>
                                  <img
                                    alt="split4"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split4}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    35 %
                                  </div>
                                </div>
                              </span>
                            )}
                            {selectedMeal.macroSplit === "low fat" && (
                              <span className="text-appback flex flex-1 items-end text-litletiny font-light font-width-75">
                                <div className="flex flex-col w-10 items-center relative mr-1">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Fats")}
                                  </div>
                                  <img
                                    alt="split1"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split1}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    15 %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Carbo- hydrates")}
                                  </div>
                                  <img
                                    alt="split4"
                                    t="split4"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split4}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    65 %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative ml-1">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Proteins")}
                                  </div>
                                  <img
                                    alt="split2"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split2}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    20 %
                                  </div>
                                </div>
                              </span>
                            )}
                            {selectedMeal.macroSplit === "low carb" && (
                              <span className="text-whiteback flex flex-1 items-end text-litletiny font-light font-width-75">
                                <div className="flex flex-col w-10 items-center relative mr-1">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Fats")}
                                  </div>
                                  <img
                                    alt="split4"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split4}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    40 %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Carbo- hydrates")}
                                  </div>
                                  <img
                                    alt="split1"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split1}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    35 %
                                  </div>
                                </div>
                                <div className="flex flex-col w-10 items-center relative ml-1">
                                  <div className="absolute bottom-6 break-normal px-3 leading-none">
                                    {t("Proteins")}
                                  </div>
                                  <img
                                    alt="split3"
                                    className="w-10 h-10 transform scale-x-130 scale-y-125"
                                    src={split3}
                                  />
                                  <div className="absolute bottom-015 text-whiteback font-medium font-width-85">
                                    25 %
                                  </div>
                                </div>
                              </span>
                            )}
                          </span>
                        </label>
                      </div>
                      <div className="p-4 text-tiny font-sans font-light">
                        <div className="flex justify-between border-b-2 mx-4">
                          <div className="font-width-75">Fett</div>
                          <div className="font-width-75">
                            {selectedMeal.foodChoice.carb} gr
                          </div>
                        </div>
                        <div className="flex justify-between  border-b-2 mx-4">
                          <div className="font-width-75">Kohlenhydrate</div>
                          <div className="font-width-75">
                            {selectedMeal.foodChoice.fat} gr
                          </div>
                        </div>
                        <div className="flex justify-between border-b-2 mx-4">
                          <div className="font-width-75">Proteine</div>
                          <div className="font-width-75">
                            {selectedMeal.foodChoice.protein} gr
                          </div>
                        </div>
                        <div className="p-4 flex w-full justify-end">
                          <span className="text-base font-bold font-width-90 leading-5">
                            {Number.parseFloat(
                              (selectedMeal.calories.value * 1.5) / 100
                            ).toFixed(2)}{" "}
                            €
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-4 flex justify-center">
                  <div className="col-span-12 font-sans text-base text-white font-bold font-width-90">
                    {t("ORDER CODE")} #435
                  </div>
                </div>
                <div className="w-full bg-dropback mx-auto">
                  <div className="bg-appback pb-8 pt-4 flex justify-center">
                    <button
                      onClick={() => resetAllVals()}
                      className="text-white font-sans text-xs font-medium font-width-85 bg-dropback w-56 py-3 px-4 rounded-full flex items-center justify-center"
                    >
                      weitere Bestellung
                    </button>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <Footer
            footerHomeLink={footerHomeLink}
            setAppLocation={setAppLocation}
          />
        </div>
      </div>
    </>
  );
};

export default PortionsGrosse;
