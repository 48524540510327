import React, { useEffect } from 'react';
import Logo from "./img/ErbsenzaehlereiLogoHellSansCircleFooter.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
//import TwitterIcon from "@mui/icons-material/Twitter";
import { grey } from "@mui/material/colors";

const Footer = ({ footerHomeLink, setAppLocation, appLocation }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="grid grid-cols-3 max-w-md text-sm font-width-90 bg-dropback">
      <div className="flex flex-col flex-1 justify-center text-gray-300 text-tiny font-sans">
        <div className="ml-4 sm:ml-8 mt-3">
          <div className="h-6">
            {appLocation === "agb" || appLocation === "impressum" ?
              <button className="font-sans" onClick={() => setAppLocation("intro")}>Home</button>
              :
              <button className="font-sans" onClick={() => footerHomeLink()}>Home</button>
            }
          </div>
          <div className="h-6">
            <button onClick={() => window.location = 'https://goo.gl/maps/na2JNsz5562SuHmx7'}>Anfahrt</button>
          </div>
          <div className="h-6">
            <button className="font-sans"  onClick={() => window.location = 'mailto:info@erbsenzaehlerei.com'}>Kontakt</button>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 justify-center items-center">
        <img className="w-28 h-1/2 mt-2 mb-2 mx-7" alt="text" src={Logo} />
        <div className="border-apptextgray flex mb-5 ">

          <FacebookIcon className="mx-2" sx={{ color: grey[100] }} onClick={() => window.location = 'https://www.facebook.com/Erbsenz%C3%A4hlerei-112812981302105'}/>
          <InstagramIcon className="mx-2" sx={{ color: grey[100] }} onClick={() => window.location = 'https://www.instagram.com/erbsenzaehlerei1/'}/>
        </div>
      </div>
      <div className="flex flex-col justify-center text-gray-300 flex-1 text-tiny">
        <div className="text-right mr-4 sm:mr-8 mt-3">
          <div className="h-6"></div>
          <div className="h-6"><button className="font-sans" onClick={() => setAppLocation("agb")}>AGB</button></div>
          <div className="h-6">
            <button className="font-sans" onClick={() => setAppLocation("impressum")}>Impressum</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
