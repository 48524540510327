import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Logo from "./img/ErbsenzaehlereiLogoDarkCircleOutlines.svg";



const Agb = ({ setAppLocation, footerHomeLink, appLocation }) => {
    const { t } = useTranslation();



    return (
        <div className="w-full flex flex-col items-center">
            <div className="bg-white flex flex-col max-w-md  mx-auto flex-1 w-full items-center">
                <button className="flex w-4/5 items-center justify-center h-full" onClick={() => footerHomeLink()}>
                    <img className="h-full" alt="text" src={Logo} />
                </button>
                <div className="my-5 text-center w-10/12 font-londrina text-giga">
                    AGB
                </div>
                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    1. Allgemeines
                </div>
                <div className="my-5  w-10/12 font-sans font-width-90 font-medium text-xs">
                    Die Mind Hill UG (haftungsbeschränkt), Ingrimstraße 38, 69117 Heidelberg (nachfolgend „Stororama“),
                    betreibt die Online-Plattform www.stororama.com (nachfolgend „Plattform“). Die Plattform bietet registrierten
                    Nutzern (nachfolgend „Nutzer“) die Möglichkeit, private Lagerplätze und Abstellräume (nachfolgend „Lagerplatz“) zu vermieten bzw.
                    anzumieten. Zu diesem Zweck können Nutzer, die ihren Lagerplatz vermieten wollen (nachfolgend „Vermieter“),
                    auf der Plattform einschlägige Angebote erstellen. Nutzer, die Lagerplatz anmieten wollen (nachfolgend „Mieter“),
                    können die Anmietung auf der Plattform vornehmen. Ferner werden auf der Plattform Informationen für registrierte und nicht
                    registrierte Nutzer bereitgestellt.
                </div>
                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    2. Nutzungsvertrag für die Plattform
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div>
                        (1) Durch die Registrierung auf der Plattform, die das vollständige Ausfüllen und Speichern der Angaben im persönlichen Plattformkonto voraussetzt, entsteht zwischen dem Nutzer und Stororama
                        ein Nutzungsvertrag. Dabei sind alle Angaben vollständig und korrekt anzugeben. Geänderte Angaben müssen ohne Verzug im persönlichen Plattformkonto aktualisiert werden.
                    </div>
                    <div className="my-3">
                        (2) Ein Anspruch auf Abschluss eines Nutzungsvertrages existiert nicht. Ein Nutzungsvertrag kann nur mit unbeschränkt geschäftsfähigen Personen abgeschlossen werden.
                        Beschränkt geschäftsfähige und geschäftsunfähige Personen dürfen sich auf der Plattform nicht registrieren.
                    </div>
                    <div className="my-3">
                        (3) Stororama stellt ausschließlich die Plattform zur Verfügung und ermöglicht die Suche nach sowie die Buchung von Lagerplatzangeboten. Stororama ist nicht der Anbieter von den auf der
                        Plattform angebotenen Lagerplätzen und berät Nutzer nicht bei der Erstellung und Auswahl der auf der Plattform veröffentlichten Angebote.
                    </div>
                    <div className="my-3">
                        (4) Die Nutzung der Plattform ist für Nutzer kostenlos.
                    </div>
                    <div className="my-3">
                        (5) Stororama ist berechtigt, ihre Dienstleistungen durch Dritte erbringen zu lassen.
                    </div>
                    <div className="my-3">
                        (6) Stororama darf die Daten der Nutzer in Übereinstimmung mit der Datenschutzerklärung zu erheben, zu verarbeiten und zu nutzen.
                    </div>
                    <div className="my-3">
                        (7) Die Nutzung der Plattform wird nur für private Zwecke gestattet. Die Plattform darf explizit nicht für betrügerische oder falsche Ver- bzw. Anmietungen verwendet werden.
                    </div>
                    <div className="my-3">
                        (8) Stororama behält sich das Recht vor, Nutzer von der Plattformnutzung auszuschließen und/oder ihre Angebote sperren oder löschen, sofern die Nutzer die Plattform missbräuchlich nutzen bzw.
                        sie und/oder ihre Angebote gegen diese AGB oder geltende Gesetze verstoßen.
                    </div>
                    <div className="my-3">
                        (9) Stororama macht alles in ihrer Macht stehende, um den Betrieb der Plattform stets und möglichst vollständig auf allen Endgeräten sicherzustellen.
                        Bei Bedarf kann Stororama jedoch die Verfügbarkeit der Plattform aufgrund von Wartungsarbeiten oder anderen Gründen einschränken. Insofern kann eine durchgehend hundertprozentige Verfügbarkeit der Plattform nicht gewährleitet werden.
                    </div>
                    <div className="my-3">
                        (10) Stororama behält sich das Recht vor, den Aufbau und die Funktionalität der Plattform jederzeit ohne Zustimmung der Nutzer zu ändern oder anzupassen.
                    </div>
                    <div className="my-3">
                        (11) Der Nutzungsvertrag wird auf unbestimmte Zeit geschlossen. Sowohl Nutzer, als auch Stororama können den Nutzungsvertrag jederzeit kündigen.
                        Stororama behält sich vor, den Betrieb der Plattform ohne Zustimmung von Nutzern einzustellen. Eine solche Betriebseinstellung gleicht einer Kündigung des Nutzungsvertrags durch Stororama.
                    </div>
                    <div className="mt-3">
                        (12) Sollten Dritte wegen Nutzung der Plattform durch den Nutzer Ansprüche gegen Stororama geltend machen (z.B. wegen Marken-, Wettbewerbs- oder Urheberrechtsverletzungen),
                        so wird der einschlägige Nutzer Stororama von diesen Ansprüchen auf erstes Anfordern hin freistellen.
                    </div>
                </div>
                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    3. Buchung von Lagerplätzen                </div>
                <div className="my-5  w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div className="">
                        (1) Durch das Absenden des ausgefüllten Anmietungsformulars durch den Klick auf den „Verbindlich mieten“-Button gibt der Mieter ein verbindliches Angebot zum Abschluss eines Mietvertrags
                        zu Nichtwohnzwecken mit dem Vermieter für den einschlägigen Lagerplatz sowie ein verbindliches Angebot zum Abschluss eines Dienstleistungsvertrages mit Stororama ab. Ein Anspruch auf Abschluss beider Verträge besteht nicht.
                    </div>
                    <div className="my-3">
                        (2) Für den Mietvertrag gelten folgende Bedingungen: <br/>
                        i. Der Mietvertrag kommt ausschließlich auf dem elektronischen Wege über die Plattform zustande. <br/>
                        ii. Der Vermieter versichert, dass er befugt ist, den von ihm angebotenen Lagerplatz zu vermieten. <br/>
                        iii. Der Vermieter nimmt das Angebot des Mieters zum Abschluss des Mietvertrags an, indem der Vermieter dieses Angebot auf der Plattform nicht spätestens 24 Stunden vor dem Startzeitpunkt der Anmietung ablehnt. Die Annahme kann auch früher erfolgen. <br/>
                        iv. Der Mietvertrag wird direkt zwischen dem Mieter und dem Vermieter abgeschlossen. Stororama ist zu keinem Zeitpunkt Partei des Mietvertrags. <br/>
                        v. Der Mietvertrag wird ausdrücklich zu Nichtwohnzwecken abgeschlossen. Sofern der Mieter bei der Anfrage eine Endfrist bestimmt, entsteht ein zeitlich befristeter Mietvertrag. Ansonsten entsteht ein zeitlich unbefristeter Mietvertrag. <br/> 
                        vi. Sowohl der Vermieter, als auch der Mieter bevollmächtigen Strororama, Willenserklärungen im Namen und für Rechnung des Vermieters bzw. des Mieters zu empfangen und abzugeben. Von diesem Recht darf Stororama nur Gebrauch machen, sofern dies gegenüber der jeweils anderen Partei des Mietvertrags erfolgt,       
                            im Zusammenhang mit dem Mietvertrag steht und dem vom Vermieter bzw. Mieter Gewollten entspricht. Als Zeitpunkt der Zustellung der Willenserklärungen gilt der Zeitpunkt des Eingangs der entsprechenden Nachricht auf dem jeweiligen Email-Server. <br/>
                        
                        vii. Der Vermieter erteilt Stororama eine eingeschränkte Inkassovollmacht, welcher der Mieter hiermit zustimmt. Diese Vollmacht ist ausschließlich auf Entgegennahme von Zahlungen des Mieters über den Zahlungsdienstleister 
                                (PayPal (Europe) S.à r.l. et Cie, S.C.A., AGB: <span className="break-words">https://www.paypal.com/de/webapps/mpp/ua/legalhub-full</span>) begrenzt. 
                                Stororama ist zur Zahlung an Vermieter verpflichtet, erst wenn die einschlägige Zahlung des Mieters endgültig erfolgt ist, und nur in Höhe dieser Zahlung. 
                                Sofern Stororama eine Zahlung, zu der Stororama verpflichtet ist, an den Vermieter nicht leistet, kann der Vermieter ausschließlich Stororama, und nicht den Mieter, in Anspruch nehmen. viii. 
                                Sowohl der Mieter, als auch der Vermieter behalten sich vor, bis zu 24 Stunden vor dem vereinbarten Vermietungsbeginn vom Mietvertrag zurückzutreten. Der Rücktritt hat auf der Plattform zu erfolgen. 
                                Im Falle eines Rücktritts verzichten sowohl der Vermieter, als auch der Mieter auf etwaige Schadensersatz- und anderweitigen Ansprüche gegenüber der jeweils anderen Partei des Mietvertrages. <br/>
                        ix. Ein befristeter Mietvertrag endet automatisch zum vereinbarten Endzeitpunkt. <br/>
                        x. Ein unbefristeter Mietvertrag kann zum Ende des jeweiligen Anmietungsmonats sowohl durch den Mieter, als auch durch den Vermieter auf der Plattform beendet werden. Hierdurch unterbreitet der Vermieter bzw. der Mieter der jeweils anderen Partei ein verbindliches Angebot zum Abschluss eines Mietaufhebungsvertrags. Diese andere Vertragspartei erklärt die Annahme des vorgenannten Angebots bereits mit Abschluss des Mietvertrages. Der Mietaufhebungsvertrag ist aufschiebend bedingt auf die Abholung der eingelagerten Sachen durch den Mieter. Erfolgt diese spätestens zum Ablauf des letzten Anmietungsmonats nicht, so läuft der Mietvertrag weiter.
                    </div>
                    <div className="mt-3">
                        (3) Für den Dienstleistungsvertrag gelten folgende Bedingungen: <br/>
                        i. Der Dienstleistungsvertrag kommt ausschließlich auf dem elektronischen Wege über die Plattform zwischen dem Mieter und Stororama zustande. <br/>
                        ii. Die Annahme des Angebots zum Abschluss des Dienstleistungsvertrages erfolgt durch konkludentes Handeln von Stororama, indem Stororama das Angebot des Mieters nicht ablehnt. <br/>
                        iii. Die Laufzeit des Dienstleistungsvertrags ist kongruent mit der Laufzeit des Mietvertrages. <br/>
                        iv. Sofern ein befristeter Mietvertrag abgeschlossen wurde, endet der Dienstleistungsvertrag automatisch zum vereinbarten Ende des Mietvertrages. Sofern ein unbefristeter Mietvertrag abgeschlossen wurde, 
                            wird der Dienstleistungsvertrag durch einen Aufhebungsvertrag zum Ende des jeweiligen Anbietungsmonats beendet. Sofern der Mietvertrag vom Mieter beendet wird, unterbreitet er gleichzeitig mit dem Angebot zum Abschluss 
                            des Mietaufhebungsvertrages auch ein Angebot an Stororama zum Abschluss des Aufhebungsvertrages zum Dienstleistungsvertrag. Sofern der Mietvertrag vom Vermieter beendet wird, unterbreitet Stororama an den Mieter ein Angebot 
                            zum Abschluss des Aufhebungsvertrages zum Dienstleistungsvertrag. Der Mieter und Stororama erklären die Annahme des Angebotes zum Abschluss des Aufhebungsvertrags zum Dienstleistungsvertag bereits mit dem Abschluss des Dienstleistungsvertrages. <br/>
                        v. Widerrufsbelehrung für Verbraucher <br/>
                        Da regelmäßig davon auszugehen ist, dass Mieter Lagerplätze auf Stororama zu privaten Zwecken anmieten, sind Mieter als Verbraucher im Sinne von §13 BGB einzustufen. Insofern gelten für Mieter die folgenden Bestimmungen:
                        Widerrufsbelehrung
                        Widerrufsrecht
                        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
                        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
                        Um ihr Widerrufsrecht auszuüben, müssen sie uns (Mind Hill UG (haftungsbeschränkt), Ingrimstraße 38, 69117 Heidelberg, Email: info@stororama.com) mittels einer eindeutigen Erklärung (per Post oder Email) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das nachstehende Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                        Zur Wahrung dieser Frist reicht es aus, dass sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                        Folgen des Widerrufs
                        Wenn sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden wir Ihnen wegen dieser Rückzahlung Entgelte berechnen.
                        Wurde die Dienstleistung auf Ihren Wunsch hin während der Widerrufsfrist erbracht, so haben sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu diesem Zeitpunkt, zu dem sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                        Muster-Widerrufsformular <br/>
                        An: <br/>
                        Mind Hill UG (haftungsbeschränkt) <br/>
                        Ingrimstraße 38 <br/>
                        69117 Heidelberg <br/>
                        Email: info@stororama.com <br/>
                        Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung: <br/>
                        Diese Dienstleistung wurde bestellt am: <br/>
                        Diese Dienstleistung wurde erhalten am: <br/>
                        Name des/der Verbraucher(s): <br/>
                        Anschrift des/der Verbraucher(s): <br/>
                        Datum: <br/>
                        Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier) <br/>
                        vi. Macht der Mieter von seinem Widerrufsrecht Gebrauch und ist zum Zeitpunkt des Widerrufs der Mietvertrag zustande gekommen, so unterbreitet der Mieter dem Vermieter gleichzeitig mit dem Widerruf ein 
                        verbindliches Angebot zum Abschluss eines Mietaufhebungsvertrags. Der Vermieter erklärt die Annahme des vorgenannten Angebots bereits mit Abschluss des Mietvertrages. Macht der Mieter von seinem Widerrufsrecht Gebrauch 
                        und ist zum Zeitpunkt des Widerrufs noch kein Mietvertrag zustande gekommen, so zieht der Mieter gleichzeitig mit dem Widerruf sein Angebot zum Abschluss des Mietvertrags zurück.
                    </div>
                </div>


                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    4. Zahlung
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div>
                        (1) Die Zahlungspflicht entsteht nur für den Mieter und nur bei Buchung von Lagerplätzen
                    </div>
                    <div className="my-3">
                        (2) Alle auf der Plattform angezeigten Gesamtmietpreise sind Endpreise. Jeder Gesamtmietpreis setzt sich zusammen aus dem durch den jeweiligen Vermieter bestimmten Mietpreis für seinen Lagerplatz und der Dienstleistungsgebühr 
                        von Stororama. Die Gesamtmietpreise enthalten die auf die Dienstleistungsgebühr von Stororama fällige Umsatzsteuer.
                    </div>
                    <div className="my-3">
                        (3) Die Gesamtmietpreise entsprechen je nach Einstellungen des Vermieters dem Preis pro Quadratmeter oder pro Gesamtfläche. Je nach Lagerungstyp entsprechen die Gesamtpreise dem Preis pro Tag oder pro Monat. Die einschlägigen 
                        Informationen sind in der Angebotsbeschreibung enthalten.
                    </div>
                    <div className="my-3">
                        (4) Die Zahlungsabwicklung erfolgt über einen Zahlungsdienstleister (PayPal (Europe) S.à r.l. et Cie, S.C.A., AGB: <span className="break-words">https://www.paypal.com/de/webapps/mpp/ua/legalhub-full</span>).
                    </div>
                    <div className="mt-3">
                        (5) Bei langfristiger Lagerung erfolgt die Zahlung am ersten Tag der Anmietung. Anschließend wiederholt sich die Zahlung in monatlichen Abständen für die Dauer des Mietvertrages und des Dienstleistungsvertrages.
                    </div>
                </div>

                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    5. Haftung von Stororama
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div>
                        (1) Stororama übernimmt keine Haftung für die Richtigkeit, Vollständigkeit, Verlässlichkeit oder Glaubwürdigkeit der Inhalte und/oder Angebote, die durch Nutzer auf der Plattform eingestellt werden.
                    </div>
                    <div className="my-3">
                        (2) Durch die Annahme der vorstehend erwähnten Vollmachten übernimmt Stororama keine Haftung für Handlungen oder Unterlassungen des Vermieters bzw. des Mieters.
                    </div>

                    <div className="mt-3">
                        (3) Bei Vorsatz und grober Fahrlässigkeit haftet Stororama unbeschränkt. Bei leichter Fahrlässigkeit haftet Stororama nur bei Verletzung von Kardinalpflichten, die den einschlägigen Vertragszweck gefährdet. 
                        Schäden an Leib und Leben sowie Gesundheit bleiben von den vorstehenden Haftungsbeschränkungen unberührt.
                    </div>
                </div>

                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    6. Folgendes darf nicht eingelagert werden
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div>
                        (1) Verderblichen Waren wie z.B. Lebensmittel
                    </div>
                    <div className="my-3">
                        (2) Gift & Chemikalien
                    </div>
                    <div className="my-3">
                        (3) Leicht entflammbares
                    </div>
                    <div className="my-3">
                        (4) Schmutzstoffe & radioaktive Stoffe
                    </div>
                    <div className="my-3">
                        (5) Sprengstoffe, Waffen & Muniton
                    </div>
                    <div className="my-3">
                        (6) Lebewesen
                    </div>
                    <div className="mt-3">
                        (7) Gesetzeswidrige Gegenstände
                    </div>
                </div>

                <div className="mt-5 w-10/12 font-londrina font-width-90 font-bold text-lg">
                    7. Sonstiges
                </div>
                <div className="my-5 w-10/12 font-sans font-width-90 font-medium text-xs">
                    <div>
                        (1) Stororama behält sich vor, diese AGB jederzeit zu ändern, sofern dies für die Nutzer zumutbar ist und diese die Nutzer nicht unangemessen benachteiligen. Die Nutzer werden zwei Wochen vor Inkrafttreten der 
                        Änderungen per E-Mail über die Änderungen informiert. Widersprechen die Nutzer nicht innerhalb von zwei Wochen nach Ankündigung der Änderung, so gelten die geänderten AGB als von den Nutzern angenommen. Auf diesen Umstand wird 
                        bei Ankündigung der Änderung erneut hingewiesen.
                    </div>
                    <div className="my-3">
                        (2) Sollten einzelne Bestimmungen dieses Vertrages unwirksam oder undurchführbar sein oder nach Vertragsschluss unwirksam oder undurchführbar werden, bleibt davon die Wirksamkeit des Vertrages im Übrigen unberührt. 
                        An die Stelle der unwirksamen oder undurchführbaren Bestimmung soll diejenige wirksame und durchführbare Regelung treten, deren Wirkungen der wirtschaftlichen Zielsetzung am nächsten kommen, die die Vertragsparteien mit der unwirksamen bzw. 
                        undurchführbaren Bestimmung verfolgt haben. Die vorstehenden Bestimmungen gelten entsprechend für den Fall, dass sich der Vertrag als lückenhaft erweist.
                    </div>
                    <div className="mt-3">
                        (3) Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Sofern rechtlich zulässig, ist Erfüllungsort für die gegenseitigen Verpflichtungen aus dem Nutzungsvertrag sowie dem Dienstleistungsvertrag der Sitz von Stororama.
                    </div>
                </div>



                <div className="w-full flex justify-center">
                    <Footer footerHomeLink={footerHomeLink} setAppLocation={setAppLocation} appLocation={appLocation} />
                </div>
            </div>
        </div>

    );
};

export default Agb;