import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Detector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
// import languageEn from "./locales/en/translation.json";
import languageDe from "./locales/de/translation.json";

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(Detector)

  .init({
    react: {
      useSuspense: false,
    },
    detection: {
      // order and from where user language should be detected
      order: [
        "navigator",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "htmlTag",
      ],
    },
    ns: ["translation"],
    resources: {
      // en: languageEn,
      de: languageDe,
    },
    debug: true,
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
