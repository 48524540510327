import React, { useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/outline";
import axios from "axios";
import split1 from "./img/MacroSplit1.svg";
import split2 from "./img/MacroSplit2.svg";
import split3 from "./img/MacroSplit3.svg";
import split4 from "./img/MacroSplit4.svg";
import { getVeganId, getIngredientIds, getEntrementIds } from './Utils';

const Zutaten = ({
  selectedMeal,
  setSelectedMeal,
  setAppLocation,
  selectedAllergy,
  setSelectedAllergy,
}) => {
  console.log("4/5: Zutaten.js");

  const { t } = useTranslation();

  useEffect(() => {

    setSelectedMeal((current) => ({
      ...current,
      ...(selectedMeal.hasOwnProperty("macroSplit") === false && {
        macroSplit: "balanced",
        constraint: {
          fat: "30",
          carb: "55",
          protein: "15",
        },
      }),
    }));

    const intolerances = selectedMeal.foodChoice.allergies;
    const did = selectedMeal.foodChoice.id;
    const veganoption = selectedMeal.veganoption;
    getIngredientIds(intolerances, did, veganoption).then( (ings) => {
      getEntrementIds(intolerances, did, veganoption).then( (ents) => {
        const mergedIngredients = [
          ...ings,
          ...ents,
        ];
        setSelectedMeal( (current) => ({
          ...current,
          foodChoice: {
            ...current.foodChoice,
            ent_ids: ents.map((ent) => ent.id),
            ing_ids: ings.map((ing) => ing.id),
            mandatoryIngredients: mergedIngredients
              .filter((i) => i.optional === "0")
              //.map((i) => i.ingredient || i.entremets)
              ,
            extras: mergedIngredients
              .filter((i) => i.optional === "1")
              ,
          },
        }));
      });
    });

  }, []);

  return (
    <>
      {/* <div className="flex flex-col w-full mx-auto bg-appback rounded-xl min-h-screen"> */}
      {/* <div className="md:max-w-full"> */}
      <div className="w-full flex flex-col items-center">
        <div className="bg-dropback flex flex-col max-w-md mx-auto w-full">
          <div className="bg-dropback px-8 flex flex-col items-center ">
            <div className="bg-white rounded-b-2xl w-full max-w-md">
              <div className="p-4 flex justify-center">
                <div className="text-xl font-londrina font-extrabold text-appback">
                  {selectedMeal.foodChoice.dish}
                </div>
              </div>
            </div>
          </div>
          <div className="flex bg-dropback py-4 items-center">
            <div
              onClick={() => setAppLocation("portionsgrosse")}
              className=" w-8 h-8 ml-3"
            >
              <ArrowLeftIcon className={`text-white cursor-pointer`} />
            </div>
            <div className="ml-2 flex verysmart:text-base text-[18px]  text-white font-bold font-width-90 tracking-wide">
              4. {t("CUSTOMIZE INGREDIENTS")}
            </div>
          </div>

          <div className="py-4 px-6 bg-appback flex flex-col items-center text-xs text-white font-width-90">
            <div className="w-full">
              <div className="mt-2 flex flex-col w-full text-xs font-width-90 font-medium px-1">
                {selectedMeal.foodChoice.extras?.map((extra, ind) => (
                  <div key={ind} className="py-1 my-0.5">
                    <label className="inline-flex items-center">
                      <div className="round flex">
                        <input
                          onClick={(e) =>
                            // adding or removing new values to selectedExtras
                            setSelectedMeal((current) =>
                              e.target.checked === true
                                // add ingredients
                                ? {
                                  ...current,
                                  foodChoice: {
                                    ...current.foodChoice,
                                    ent_ids: extra.entremets
                                      ? [...current.foodChoice.ent_ids, extra.id]
                                      : [...current.foodChoice.ent_ids]
                                    ,
                                    ing_ids: extra.ingredient
                                      ? [...current.foodChoice.ing_ids, extra.id]
                                      : [...current.foodChoice.ing_ids]
                                    ,
                                  },
                                }
                                // remove ingredients
                                : {
                                  ...current,
                                  foodChoice: {
                                    ...current.foodChoice,
                                    ent_ids: current.foodChoice.ent_ids.filter(
                                      //(ext) => extra[1] !== "entremets" || ext !== extra[0]
                                      (ext) => !extra.entremets || ext !== extra.id
                                    ),
                                    ing_ids: current.foodChoice.ing_ids.filter(
                                      //(ext) => extra[1] !== "ingredient" || ext !== extra[0]
                                      (ext) => !extra.ingredient || ext !== extra.id
                                    ),
                                  }
                                }
                            )
                          }
                          defaultChecked={
                            selectedMeal.foodChoice.ent_ids.filter(
                              (i) => extra.entremets && i === extra.id
                            ).length > 0
                              ? true
                              : selectedMeal.foodChoice.ing_ids.filter(
                                  (i) => extra.ingredient && i === extra.id
                                ).length > 0 ? true : false
                          }
                          type="checkbox"
                          id={ind}
                        />
                        <label htmlFor={ind}></label>
                      </div>
                      <span className="ml-2">{extra.ingredient||extra.entremets}</span>
                    </label>
                  </div>
                ))}
              </div>
              <Disclosure as="div" className="py-3 w-full font-width-90 pl-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full text-left">
                      <div className="round flex mt-1 opacity-40">
                        <input defaultChecked={true} type="checkbox" />
                        <label></label>
                      </div>
                      <div className="flex justify-between w-full ext-xs font-width-90 font-medium">
                        <div className="ml-2 flex flex-col text-xs">
                          <span className="">
                            Grundzutaten {selectedMeal.foodChoice.dish}
                          </span>
                          <span>(immer enthalten)</span>
                        </div>
                        <ChevronDownIcon
                          className={`${open ? "transform rotate-180" : ""
                            } w-6 h-6 text-white`}
                        />
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <div className=" text-apptextgray text-xs">
                        <div className="py-3">
                          <div>
                            {selectedMeal.foodChoice.mandatoryIngredients?.map(
                              (i, ind, arr) => (
                                <span className="" key={ind}>
                                  {arr.length === ind + 1
                                    ? i.ingredient||i.entremets + "."
                                    : i.ingredient||i.entremets + "; "}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-full">
              <div className="bg-dropback">
                <div className="flex flex-col justify-center py-6">
                  <div className="flex items-center">
                    <div className=" w-8 h-8 ml-3" >
                    </div>
                    <div className="ml-2 verysmart:text-base text-[18px] text-white font-bold font-width-90 tracking-wide">
                      5. {t("CHOOSE MACRO-SPLIT")}  <br />
                      <span className="text-tiny text-white font-medium font-width-85 h-10">{t(
                        "Nutrient distribution according to proteins, fats and carbohydrates"
                      )}</span>
                    </div>
                  </div>


                  <div className="pr-4 flex h-full items-center justify-center"></div>
                </div>
              </div>

              <div className="p-6 text-apptextgray flex flex-col bg-appback">
                {/* <div className="pt-8 pb-6"> */}

                {/* 1/4 balanced */}
                <label className="flex p-2 h-20 items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-2.5 flex">
                      <input
                        className="w-2 h-2 text-transparent bg-appback focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        value="1"
                        onChange={() =>
                          setSelectedMeal((current) => ({
                            ...current,
                            macroSplit: "balanced",
                            constraint: {
                              fat: "30",
                              carb: "55",
                              protein: "15",
                            },
                          }))
                        }
                        checked={
                          selectedMeal.macroSplit === "balanced" ? true : false
                        }
                      />
                    </div>
                    <span className="text-white w-20 ml-2 text-xs font-width-90 font-medium">
                      {t("Balanced")}
                    </span>
                  </div>
                  <span className="text-white flex items-end text-litletiny font-light font-width-75 sm:pr-3  transform  scale-x-100 scale-y-100 verysmart:scale-x-130  verysmart:scale-y-125 verysmart:mr-4 mr-0">
                    <div className="flex flex-col w-10 items-center relative mr-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Fats")}
                      </div>
                      <img
                        alt="split3"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split3}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        30 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Carbo- hydrates")}
                      </div>
                      <img
                        alt="split3"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split3}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        55 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative ml-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Proteins")}
                      </div>
                      <img
                        alt="split1"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split1}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        15 %
                      </div>
                    </div>
                  </span>
                </label>
                {/* 2/4 low fat */}
                {/*
                <label className="flex p-2 h-20 items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-2.5 flex">
                      <input
                        className="w-2 h-2 text-transparent bg-appback focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        value="1"
                        onChange={() =>
                          setSelectedMeal((current) => ({
                            ...current,
                            macroSplit: "low fat",
                            constraint: {
                              fat: "15",
                              carb: "65",
                              protein: "20",
                            },
                          }))
                        }
                        checked={
                          selectedMeal.macroSplit === "low fat" ? true : false
                        }
                      />
                    </div>
                    <span className="text-white w-20 ml-2  text-xs font-width-90 font-medium">
                      {t("Low Fat")}
                    </span>
                  </div>
                  <span className="text-white flex items-end text-litletiny font-light font-width-75 sm:pr-3  transform  scale-x-100 scale-y-100 verysmart:scale-x-130  verysmart:scale-y-125 verysmart:mr-4 mr-0">
                    <div className="flex flex-col w-10 items-center relative mr-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Fats")}
                      </div>
                      <img
                        alt="split1"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split1}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        15 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Carbo- hydrates")}
                      </div>
                      <img
                        al
                        alt="split4"
                        t="split4"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split4}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        65 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative ml-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Proteins")}
                      </div>
                      <img
                        alt="split2"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split2}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        20 %
                      </div>
                    </div>
                  </span>
                </label>
                */}
                {/* 3/4 low carb */}
                <label className="flex p-2 h-20 items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-2.5 flex">
                      <input
                        className="w-2 h-2 text-transparent bg-appback focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        value="1"
                        onChange={() =>
                          setSelectedMeal((current) => ({
                            ...current,
                            macroSplit: "low carb",
                            constraint: {
                              fat: "40",
                              carb: "35",
                              protein: "25",
                            },
                          }))
                        }
                        checked={
                          selectedMeal.macroSplit === "low carb" ? true : false
                        }
                      />
                    </div>
                    <span className="text-white w-20 ml-2 text-xs font-width-90 font-medium ">
                      Low Carb
                    </span>
                  </div>
                  <span className="text-white flex items-end text-litletiny font-light font-width-75 sm:pr-3  transform scale-x-100 scale-y-100 verysmart:scale-x-130  verysmart:scale-y-125 verysmart:mr-4 mr-0">
                    <div className="flex flex-col w-10 items-center relative mr-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Fats")}
                      </div>
                      <img
                        alt="split4"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split4}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        40 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Carbo- hydrates")}
                      </div>
                      <img
                        alt="split1"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split1}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        35 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative ml-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Proteins")}
                      </div>
                      <img
                        alt="split3"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split3}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        25 %
                      </div>
                    </div>
                  </span>
                </label>
                {/* 4/4 high protein */}
                <label className="flex p-2 h-20 items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-2.5 flex">
                      <input
                        className="w-2 h-2 text-transparent bg-appback focus:border-transparent border-transparent ring-buttonback ring-2 focus:ring-buttonback ring-offset-2 ring-offset-appback focus:ring-offset-appback checked:bg-white disabled"
                        type="radio"
                        name="radio"
                        value="1"
                        onChange={() =>
                          setSelectedMeal((current) => ({
                            ...current,
                            macroSplit: "high protein",
                            constraint: {
                              fat: "20",
                              carb: "45",
                              protein: "35",
                            },
                          }))
                        }
                        checked={
                          selectedMeal.macroSplit === "high protein"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <span className="text-white 0 ml-2  text-xs font-width-90 font-medium">
                      High Protein
                    </span>
                  </div>
                  <span className="text-white flex items-end text-litletiny font-light font-width-75 sm:pr-3  transform scale-x-100 scale-y-100 verysmart:scale-x-130  verysmart:scale-y-125 verysmart:mr-4 mr-0">
                    <div className="flex flex-col w-10 items-center relative mr-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Fats")}
                      </div>
                      <img
                        alt="split2"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split2}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        20 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Carbo- hydrates")}
                      </div>
                      <img
                        alt="split2"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split2}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        45 %
                      </div>
                    </div>
                    <div className="flex flex-col w-10 items-center relative ml-1">
                      <div className="absolute bottom-6 break-normal px-3 leading-none">
                        {t("Proteins")}
                      </div>
                      <img
                        alt="split4"
                        className="w-10 h-10 transform scale-x-130 scale-y-125"
                        src={split4}
                      />
                      <div className="absolute bottom-015 text-black font-medium font-width-85">
                        35 %
                      </div>
                    </div>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="bg-appback py-8 px-16">
            <div className="pb-2 flex justify-center">
              <button
                onClick={() => {
                  // Order the current dish
                  setAppLocation("bestellung");
                }}
                className="bg-buttonback w-56 py-3 px-4 rounded-full flex items-center justify-center"
              >
                <span className="text-white font-sans text-xs font-medium font-width-85">
                  {t("Zur Bestellung")}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Zutaten;
